<main>
  <section class="flex justify-center">
    <div class="flex flex-col w-fit py-12">
      <img src="assets/images/icons/personalInfo/shape.svg" alt="" class="w-20">
      <h2 class="text-center px-12 py-4 font-semibold" transloco="home.contact-us.title"></h2>
      <img src="assets/images/icons/personalInfo/shape2.svg" alt="" class="w-32 ml-auto">
    </div>
  </section>
  <section class="flex justify-center mb-16">
    <app-contact-form></app-contact-form>
  </section>
</main>