import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../settings.service';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { InquiryTicket } from '../models/inquiry-ticket.model';

@Injectable({
  providedIn: 'root'
})
export class InquiryTicketService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  async createInquiryTicket(jsonData: any) {
    const res = this.http.post<InquiryTicket>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.CreateInqTicket}`, jsonData)
    const data = lastValueFrom(res)

    return data
  }
}
