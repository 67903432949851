import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transactionStatusColor',
  standalone: true
})
export class TransactionStatusColorPipe implements PipeTransform {

  transform(status: string): string {
    if (!status) return 'bg-defaultColor text-defaultColor';
    
    const normalizedStatus = status.toLowerCase().replaceAll(' ', '-');

    switch (normalizedStatus) {
      case 'succeeded':
        return 'bg-greenLight text-green';
      case 'failed':
      case 'error':
      case 'card-authentication-failed':
        return 'bg-red text-white';
      case 'refunded':
        return 'bg-yellowLight text-yellow';
      case 'pending':
        return 'bg-blueLight text-blue';
      case 'voided':
        return 'bg-greyLight text-grey';
      default:
        return 'bg-transparent text-black';
    }
  }
}
