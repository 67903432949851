import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-about-page',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent {

  constructor(
    private titleService: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.updateMetaTags(
      '#VNumber | About us',
      'virtual number, vnumber, connectivity ',
      'Expand horizons, stay local'
    );
  }

  updateMetaTags(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      rel: 'canonical',
      href: 'https://vnumber.com/about-us',
    });
  }
}
