import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { SpinnerComponent } from '../loading/spinner/spinner.component';
import { SpinnerSmallComponent } from "../loading/spinner-small/spinner-small.component";

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [TranslocoModule, SpinnerComponent, SpinnerSmallComponent],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent {
  @Input() pageIndex;
  @Input() isLoading;
  @Input() totalRecordsPerPage;
  @Input() hasMoreRecords: boolean = true;
  @Output() pageChange = new EventEmitter<{pageIndex: number, totalRecordsPerPage: number}>();

  handlePrevious(): void {
    if (this.pageIndex > 1) {
      this.pageIndex--;
      this.emitPageChange();
    }
  }

  handleNext(): void {
    if (this.hasMoreRecords) {
      this.pageIndex++;
      this.emitPageChange();
    }
  }

  private emitPageChange(): void {
    this.pageChange.emit({
      pageIndex: this.pageIndex,
      totalRecordsPerPage: this.totalRecordsPerPage
    });
  }
}
