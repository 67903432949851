import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ICreditCardInfo } from '@app/services/models/credit-card-info.model';
import { PaymentMethodsService } from '@app/services/payment-methods/payment-methods.service';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-credit-card',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './credit-card.component.html',
  styleUrl: './credit-card.component.scss'
})
export class CreditCardComponent {
  paymentMethodsService = inject(PaymentMethodsService);

  @Input() savedCards: ICreditCardInfo[] = [];
  displayedCards: ICreditCardInfo[] = [];
  showAllCards: boolean = false;

  selectedCard: ICreditCardInfo | null = null;
  selectedCardIndex: number = 0;

  @Output() selectedPaymentId = new EventEmitter<number>()

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    console.log(this.savedCards);
    this.displayedCards = this.savedCards.slice(0, 1);
    const primaryCard = this.savedCards.find(card=>card.isPrimary === true)
    this.selectCard(primaryCard.creditCardId)
  }

  ngOnChanges() {
    this.updateDisplayedCards()
    this.cdr.detectChanges();
  }
  
  selectCard(index: number) {
    this.selectedCardIndex = index; // Updates the index of the selected card
    this.getPaymentMethod(index)
    this.selectedPaymentId.emit(index)
  }
  
  isCardSelected(index: number): boolean {
    return this.selectedCardIndex === index;
  }

  private updateDisplayedCards() {
    if (this.savedCards.length > 0) {
      this.displayedCards = this.showAllCards ? this.savedCards : this.savedCards.slice(0, 1);
      const primaryCard = this.savedCards.find(card => card.isPrimary === true);
      if (primaryCard && !this.selectedCard) {
        this.selectCard(primaryCard.creditCardId);
      }
    }
  }

  async getPaymentMethod(cardId: number) {
    try {
      const res = await this.paymentMethodsService.getCreditCardById<ICreditCardInfo>(cardId);

      if (res.isSuccessful && res.result) {
        this.selectedCard = res.result
      }
    } catch (error) {
      console.error(error);
    }
  }

  showMore() {
    this.showAllCards = true;
    this.displayedCards = this.savedCards; // Mostrar todas las tarjetas
  }
}
