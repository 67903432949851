<main class="px-5 flex flex-col md:flex-row justify-center items-center gap-8 py-32">

  <section class="flex flex-col items-center w-fit">
    <h1 class="text-orange text-center px-12 font-semibold leading-none" transloco="not-found-vn.title"></h1>
    <span class="p-6 " transloco="not-found-vn.subtitle"></span>
    <button routerLink="/" class="flex gap-2 items-center bg-orange rounded-xl py-1 px-8 text-white font-semibold uppercase w-fit">
      <span class="rotate-180">
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.3558 15.6506L25.3547 15.6495L19.0273 9.35262C18.5533 8.8809 17.7866 8.88266 17.3147 9.35674C16.8429 9.83076 16.8448 10.5975 17.3188 11.0692L21.567 15.2969H5.21094C4.54214 15.2969 4 15.839 4 16.5078C4 17.1766 4.54214 17.7187 5.21094 17.7187H21.5669L17.3188 21.9464C16.8448 22.4182 16.843 23.1849 17.3148 23.6589C17.7866 24.133 18.5534 24.1347 19.0273 23.663L25.3547 17.3661L25.3558 17.365C25.8301 16.8916 25.8286 16.1224 25.3558 15.6506Z" fill="white"/>
          </svg>
      </span>
      <span transloco="btn-vn.return"></span>
    </button>
  </section>
  <img src="assets/images/icons/testimonial_1vnumber.svg" alt="" class="w-80">
</main>