import { computed, Injectable, signal } from '@angular/core';
import { PopUpService } from '../pop-up/pop-up.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _loading = signal(false);
  private _showPopUp = signal(false);
  private timeout: any;

  isLoading = computed(() => this._loading());

  constructor(private popUpService: PopUpService) {}

  setLoading(isLoading: boolean) {
    this._loading.set(isLoading);
    if (isLoading) {
      this.startTimeout();
    } else {
      this.clearTimeout();
    }
  }

  private startTimeout() {
    this.clearTimeout();
    this.timeout = setTimeout(() => {
      this._loading.set(false);
      this._showPopUp.set(true);
      this.popUpService.openPopUp('server-error');
    }, 25000);
  }

  private clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  closePopUp() {
    this._showPopUp.set(false);
  }
}