import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-btn-login',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './btn-login.component.html',
  styleUrl: './btn-login.component.scss'
})
export class BtnLoginComponent {
  constructor(private router: Router) {}

  navigateToLogin() {
    this.router.navigate(['/login']);
  }
}
