<app-spinner [isLoading]="loadingService.isLoading()"></app-spinner>

<app-info-pop-up *ngIf="popUpService.showPopUp()" [showPopUp]="popUpService.showPopUp()"
  (closePopUpEvent)="onClosePopUp()" [translate]="popUpService.popUpMessage()">
</app-info-pop-up>

<div [dir]="textDir">
  <app-header></app-header>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <app-footer></app-footer>

  <app-whatsapp-btn></app-whatsapp-btn>
  <app-scroll-to-top-btn></app-scroll-to-top-btn>
</div>