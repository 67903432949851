import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'randomColor',
  pure: true,
  standalone: true
})
export class RandomColorPipe implements PipeTransform {
  private colorCache: { [key: string]: string } = {};

  transform(value: string): string {
    if (!this.colorCache[value]) {
      this.colorCache[value] = this.getRandomColor();
    }
    return this.colorCache[value];
  }

  private getRandomColor(): string {
    let color = '';
    do {
      color = '#' + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0');
    } while (this.isColorTooLight(color));
    return color;
  }

  private isColorTooLight(color: string): boolean {
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 200;
  }
}