<aside class="rounded-xl shadow-full border border-greyLight p-6 w-full mb-8">
  <ul class="flex flex-col gap-5">
    <li [class.router-link-active]="isActive('/profile/my-vnumbers')">
      <a routerLink="/profile/my-vnumbers" routerLinkActive="bg-grey text-white hover:bg-orange"
        class="flex px-9 py-3 hover:bg-greyLight rounded-xl gap-3">
        @if (isActive('/profile/my-vnumbers')) {
          <img src="assets/images/icons/profile/sim-w.svg" alt="user icon" class="">
        } @else {
          <img src="assets/images/icons/profile/sim.svg" alt="user icon" class="">
        }
        <span class="text-sm font-semibold" transloco="profile-vn.sidebar.my-vnumbers"></span>
      </a>
    </li>

    <li [class.router-link-active]="isActive('/profile/account-information')">
      <a routerLink="/profile/account-information" routerLinkActive="bg-grey text-white hover:bg-orange"
        class="flex px-9 py-3 hover:bg-greyLight rounded-xl gap-3">
        @if (isActive('/profile/account-information')) {
          <img src="assets/images/icons/profile/user-w.svg" alt="user icon" class="">
        } @else {
          <img src="assets/images/icons/profile/user.svg" alt="user icon" class="">
        }
        <span class="text-sm font-semibold" transloco="profile-vn.sidebar.account-info"></span>
      </a>
    </li>

    <li [class.router-link-active]="isActive('/profile/billing')">
      <a routerLink="/profile/billing" routerLinkActive="bg-grey text-white hover:bg-orange"
        class="flex px-9 py-3 hover:bg-greyLight rounded-xl gap-3">
        @if (isActive('/profile/billing')) {
          <img src="assets/images/icons/profile/card-w.svg" alt="help icon" class="" />
        } @else {
          <img src="assets/images/icons/profile/card.svg" alt="help icon" class="" />
        }   
        <span class="text-sm font-semibold" transloco="profile-vn.sidebar.billing"></span>
      </a>
    </li>

    <li [class.router-link-active]="isActive('/profile/support')">
      <a routerLink="/profile/support" routerLinkActive="bg-grey text-white hover:bg-orange"
        class="flex px-9 py-3 hover:bg-greyLight rounded-xl gap-3">
        @if (isActive('/profile/support')) {
          <img src="assets/images/icons/profile/help-w.svg" alt="help icon" class="" />
        } @else {
          <img src="assets/images/icons/profile/help.svg" alt="help icon" class="" />
        }          
        <span class="text-sm font-semibold" transloco="profile-vn.sidebar.support"></span>
      </a>
    </li>

    <!-- <li [class.router-link-active]="isActive('/profile/free-credit')">
      <a routerLink="/profile/free-credit" routerLinkActive="bg-grey text-white hover:bg-orange"
        class="flex px-9 py-3 hover:bg-greyLight rounded-xl gap-3">
        @if (isActive('/profile/free-credit')) {
          <img src="assets/images/icons/profile/dollar-w.svg" alt="notification icon" class="" />
        } @else {
          <img src="assets/images/icons/profile/dollar.svg" alt="notification icon" class="" />
        }
        <span class="text-sm font-semibold" transloco="profile-vn.sidebar.free-credit"></span>
      </a>
    </li> -->
  </ul>
</aside>