import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-nav-links',
  standalone: true,
  imports: [RouterModule, TranslocoModule],
  templateUrl: './nav-links.component.html',
  styleUrls: ['./nav-links.component.scss']
})
export class NavLinksComponent {
  @Output() linkClick: EventEmitter<void> = new EventEmitter<void>();

  constructor () {}

  onLinkClick() {
    // Emite el evento cuando se hace clic en un enlace
    this.linkClick.emit();
  }

  ngOnInit(): void {}
}
