<main class="p-10 md:px-16 mlg:px-36">
  <section
    class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] py-16 animate-fade-down">
    <h2 class="text-center text-4xl font-semibold">Terms & Conditions</h2>
  </section>

  <section class="animate-fade-up">
    <p class="text-center text-[15px] p-12">Welcome to the www.Globusim.com website (the 'Site'), which is provided to
      you by 011 Global Inc. ('Globusim'). Your use of the Site and Globusim's services, whether offered directly by
      Globusim or through a travel partner, are subject to the following Terms of Use and any operating rules or
      policies that may be published from time to time by Globusim. Please read these Terms of Use carefully. By
      checking the 'I have read and accept the terms and conditions of the Globusim Terms of Use' box during
      registration for an account or by using the Site or the Services (as defined below) you agree to be bound by these
      Terms of Use. If you do not agree with or cannot comply with the Terms of Use, please do not use the Site or the
      Services.</p>

    <h3 class="font-medium text-orange text-base">Changes to the terms of Use</h3>
    <p class="text-[15px] pb-6">Globusim may, in its sole discretion, change, add or remove any portion of the Terms of
      Use at any time by posting new Terms of Use on the Site. Your continued use of the Site or the Services after such
      changes are posted will constitute your agreement to such changes.</p>
    <h3 class="font-medium text-orange text-base">The Services</h3>
    <p class="text-[15px] pb-6">Globusim offers a number of products and services on the Site and through partners, such
      as international pay-as-you-go mobile phones and SIM cards (collectively, the 'Services'). Registration and
      payment may be required to use the Services. Pre-payment using a credit card is also required for the use of
      certain Services. Globusim may require proof of identity prior to the sale of or use of the Services. Eligibility
      restrictions, if any, will be noted when registering for each Service. Globusim will keep a record of all Services
      provided to you.</p>
    <h3 class="font-medium text-orange text-base">Mobile Equipment; SIM Card; Telephone Number</h3>
    <p class="text-[15px] pb-6">You are responsible for your own end material (such as handsets, plug adaptors and
      chargers) and its proper configuration and installation, in compliance with any regulations and applicable
      instructions by Globusim or the manufacturer. You are also responsible for all preparation to install the Service,
      such as preparing and inserting SIM cards (interface cards) and setting adequate protocols. You agree to follow
      all instructions to properly insert the SIM card into your mobile phone. You have no right to be assigned or keep
      a particular phone number. Globusim has the right to cancel or change any phone number or other addressed element
      it may have assigned to you without compensation, if required for operational or technical reasons or as a result
      of government regulations or new partnerships. Globusim does not provide mobile phone number or SIM card
      portability. Any and all rights attached to a phone number assigned by Globusim to you remain vested in Globusim.
      All SIM cards remain the property of Globusim, which reserves the rights to alter and develop its content as well
      as claim its return.</p>
    <h3 class="font-medium text-orange text-base">GSM Network Coverage</h3>
    <p class="text-[15px] pb-6">Globusim offers mobile phone and SIM card users a high degree of coverage via the GSM
      mobile network. Coverage depends on the network, technical and operational capabilities of the roaming partner.
      Gaps in coverage may nevertheless be encountered even in areas that are designated as covered, particularly inside
      buildings or remote areas. Lists and maps of coverage areas are available on the Site. Globusim will update this
      information when additional service becomes available. Globusim will make every effort to provide a high level of
      availability for its mobile network. However, Globusim is unable to guarantee continuous, trouble-free operation
      of its mobile network or certain transmission times or capacities (SMS, for example). Globusim reserves the right
      to service its mobile network at any time, which may result in temporary disruptions. No representations or
      guarantees are made with regard to availability, quality, operation or support for voice or data communication on
      third-party networks or with third-party lines.</p>
    <h3 class="font-medium text-orange text-base">Modifications to the Site or the Services</h3>
    <p class="text-[15px] pb-6">Globusim reserves the right, for any reason, in its sole discretion, to terminate, suspend or change any aspect of the Site or the Services including but not limited to products, services, content, features or hours of availability. Globusim may impose limits on certain features of the Site or the Services, or restrict your access to part or all of the Site or the Services without notice or penalty.</p>
    <h3 class="font-medium text-orange text-base">Charges for the Services</h3>
    <p class="text-[15px] pb-6">You agree to pay all charges for requested Services in US Dollars. For Services which require pre-payment, you agree to pre-pay for the Service by debiting your credit card the selected amount. The cost of the Service you use will be debited against the prepaid credit using the then current prices for the Service. In the event your charges exceed your pre-paid credit, you agree to pay any amounts owed to Globusim within seven (7) days from Globusim's request or Globusim may immediately suspend the Services. You agree to pay for all charges associated with your account, whether or not the use of your PIN was authorized by you. The charges will be deemed to be correct if they accurately reflect the technical records created by a correctly programmed system. You can request an extract of the Service records in writing (by mail, fax or email) from Customer Service within forty-five (45) days after the disputed charge. Globusim reserves the right to change the prices for the Services at any time. The current applicable prices, which are quoted in US Dollars, may be found on the Site or by calling Customer Service.</p>
    <h3 class="font-medium text-orange text-base">Off Network Usage</h3>
    <p class="text-[15px] pb-6">All Globusim SIM cards are designed to work within the countries where pricing is
      published for that particular SIM card on the www. Globusim.com website under the “Plans” tab. Users should be
      familiar with the list of countries where pricing in published and should only use their Globusim in countries
      where pricing is published. In the event that the Globusim is used in a country where no prices are published, the
      user is responsible for payment of these services and funds for the services will be deducted from the user’s
      account.</p>
    <h3 class="font-medium text-orange text-base">Expiration and Service De-Activation</h3>
    <p class="text-[15px] pb-6">Some of the SIM cards may be used again. Please follow the instructions that come with
      the SIM card.</p>
    <p class="text-[15px] pb-6">Once the SIM’s U.S. or Canadian phone number of the SIM is disconnected due to lack of
      funds on the SIM, the new number will be assigned at the time the SIM is recharged again.</p>
    <h3 class="font-medium text-orange text-base">Refunds</h3>
    <p class="text-[15px] pb-6">Any unused credit or data will not be refunded. You may return an unused Globusim SIM
      card within 30 days after the receipt of the item, provided the activation date of the SIM has not passed.
      Returned products must be undamaged and unused in their original packaging in order to be accepted. We reserve the
      right to refuse refunds for any products that are returned with damage and are unable to be resold at the
      purchased price. Shipping charges will not be refunded. We cannot refund airtime used on mis-dialed calls. If you
      are not sure how to dial a number in a specific country, please request support from customer service. There are
      no refunds for sent SMS messages - whether it was delivered to the recipient or not. Not all SMS messages can be
      or will be delivered. We cannot refund minutes used for calls to Customer Service. There will be no refunds for
      Internet data usage. If the charge occurred then data was moved and you were charged accordingly. There will be no
      refunds for Personal Extra Numbers once ordered. There will be no refunds for discount call or text plans once
      activated.</p>
    <h3 class="font-medium text-orange text-base">Ownership of SIM card phone numbers</h3>
    <p class="text-[15px] pb-6">You understand and agree that you are not the owner of any SIM phone number assigned to
      you by Globusim. Ownership of any such number is vested solely in Globusim (which will assign such number to you
      for your use during the term of this agreement). You understand and agree that following the termination of your
      account for any reason, such number may be re-assigned immediately to another customer, and you agree that
      Globusim will not be liable for damages (including consequential or special damages) arising out of any such
      re-assignment, and you hereby waive any claims with respect to any such re-assignment, whether based on
      contractual, tort or other grounds, even if Globusim has been advised of the possibility of damages. You further
      understand and agree that Globusim will disconnect your SIM phone number once there is no funds on the SIM to
      cover the monthly SIM maintenance fee or you did not reserve the SIM phone number with the separate annual fee.
      You further understand and agree that Globusim may from time to time need to change the phone number assigned to
      you (whether due to an area code split or any other reason whether outside or within Globusim’s control). You
      agree that Globusim will not be liable for damages (including consequential or special damages) arising out of any
      such change in the phone number assigned to your SIM, and you hereby waive any claims with respect to any such
      change, whether based on contractual, tort or other grounds, even if Globusim has been advised of the possibility
      of damages. In particular, you agree that you are not authorized to charge services provided to you or at your
      request to the number assigned to you by Globusim and that you will not request or otherwise cause any third-party
      service provider to charge any such services to such number. Any such charges will give Globusim the right to
      immediately terminate your account without notice.</p>
    <h3 class="font-medium text-orange text-base">No Insurance Coverage</h3>
    <p class="text-[15px] pb-6">Globusim will not be liable for, nor shall any adjustment, refunds or credit of any kind
      made as a result of any loss, damage, delay, misdelivery, nondelivery, misinformation or failure to provide
      information including, but not limited to, any such loss, damage, delay, misdelivery, nondelivery, misinformation
      or failure to provide information caused by or resulting from: (a) delays caused by Customs' clearance procedures
      or those of other regulatory agencies; (b) delays in delivery caused by adherence to shipping company policies
      regarding the payment of duties and taxes; (c) Globusim's inability to provide a copy of the delivery record; (d)
      Globusim's failure to notify you of any delay or loss of your shipment and its contents; (e) shipments released
      without obtaining a signature if the recipient has provided authorization by signing a release delivery
      authorization and indemnification agreement; or (f) Globusim's shipping company's failure or inability to attempt
      to contact the sender or recipient concerning incomplete or inaccurate address; incorrect, incomplete, inaccurate
      or missing documentation; payment of duties and taxes necessary to release a shipment; or an incomplete or
      incorrect customs broker's address. Globusim will also not be liable for any package where our records do not
      reflect that you made a purchase from us.</p>
    <h3 class="font-medium text-orange text-base">Account and PIN</h3>
    <p class="text-[15px] pb-6">You agree that all account information provided by you is accurate and up-to-date, and
      you will promptly notify Globusim of any changes. You are solely responsible for protecting the confidentiality of
      your on-line User ID and Password.</p>
    <h3 class="font-medium text-orange text-base">User Conduct</h3>
    <p class="text-[15px] pb-1">The Site and Services are provided to individuals only. Any unauthorized commercial use
      or resale of the Site or the Services is prohibited. The use of the Site or the Services to solicit business for
      any competitive website or service is also prohibited. You are solely responsible for the content and context of
      any materials you post or submit through the Site or the Services or that are posted or submitted using your
      account. Please choose carefully what you post or submit. You agree to comply with the Terms of Use and all
      applicable local, state, national and international laws, regulations, ordinances, and rules when using the Site
      and the Services. You warrant and agree that while using the Site and the Services, you will not:</p>
    <ul class="pl-2">
      <li>- use the Site or the Services in connection with chain letters, junk email, spamming, any duplicative or
        unsolicited messages (commercial or otherwise), or advertising of any kind;</li>
      <li>- harvest or otherwise collect information about others, including email addresses, without their consent;
      </li>
      <li>- create a false identity or forged email address or header, or otherwise attempt to mislead others as to the
        identity of the sender or the origin of a message;</li>
      <li>- use the Site or the Services for anything other than personal or corporate purposes without prior consent by
        Globusim.com;</li>
      <li>- upload, post, transmit, distribute or otherwise publish through the Site or the Services unlawful,
        harassing, defamatory, abusive, threatening, harmful, vulgar, obscene, false, misleading, offensive or otherwise
        objectionable material;</li>
      <li>- upload, post, transmit, distribute or otherwise publish any material that may infringe the intellectual
        property rights or other rights of third parties, including without limitation, trademarks or copyright;</li>
      <li>- upload, post, transmit, distribute or otherwise publish any material that contains viruses, trojan horses,
        worms, time bombs, cancelbots, or any other harmful or deleterious programs;</li>
      <li>- upload, post, transmit, distribute or otherwise publish a photograph of another person without that person's
        consent;</li>
      <li>- solicit personal information from anyone under the age of 18;</li>
      <li>- use the Site or the Services to violate any applicable law restricting the export or import of data,
        software or any other content;</li>
      <li>- interfere with or disrupt networks connected to the Site or the Services or violate the regulations,
        policies or procedures of such networks;</li>
      <li>- gain or attempt to gain unauthorized access to the Site or the Services, other accounts, computer systems or
        networks connected to the Site or the Services, through password mining or by any other means;</li>
      <li>- interfere with another member's use and enjoyment of the Site or the Services or another entity's use and
        enjoyment of similar services;</li>
      <li>- use the Site or the Services in any unethical manner or contrary to accepted community standards; or engage
        in or encourage conduct that would constitute a criminal offense or give rise to civil liability.</li>
    </ul>
    <h3 class="font-medium text-orange text-base">Responsibility for Use of Mobile Lines</h3>
    <p class="text-[15px] pb-6">You are solely responsible for the content of information and communications transmitted
      using the Site and the Services. You are responsible for all use of your mobile line, including use by third
      parties. In particular, you are responsible for the payment of all charges arising in connection with use of your
      mobile line including goods and services ordered or obtained via your mobile line. PIN/PIN2 and PUK/PUK2 codes and
      any other security codes that may be assigned should be stored carefully and separately from the mobile phone and
      the SIM card, and should not be disclosed to anyone. You must comply with all reasonable security procedures and
      standards with respect to the Service, including activating and periodically changing your PIN code. Globusim may
      communicate security issues to you when abuse or misuse is observed or reported by others.</p>
    <h3 class="font-medium text-orange text-base">Intellectual Property</h3>
    <p class="text-[15px] pb-6">You acknowledge and agree that all content and materials available on the Site and via
      the Services, including but not limited to the Globusim name and logos, text, music, software, photographs, and
      video, are protected by intellectual property and other laws. Except as expressly authorized by Globusim, any use,
      copy, reproduction, display, performance, modification or transmission is strictly prohibited. Notwithstanding the
      above, you may download one copy of the content and materials on the Site on any single computer for your personal
      non-commercial use, provided you keep intact all proprietary and other notices.</p>
    <p class="text-[15px] pb-6">Globusim does not claim any ownership rights in the content or materials posted by you
      or other members. By uploading, posting, transmitting, distributing or otherwise publishing content and materials
      on the Site or via the Services, you: (a) grant Globusim a non-exclusive, worldwide, royalty-free, sublicensable
      and transferable license to use, modify, publicly perform, publicly display, reproduce, edit, translate,
      aggregate, reformat, prepare derivative works based upon, distribute and otherwise exploit the content and
      materials, in whole or in part, on the Site and via the Services; and (b) represent and warrant that you own the
      content and materials or have the right to grant the license to Globusim.</p>
    <p class="text-[15px] pb-1">In appropriate circumstances and at its sole discretion, Globusim may suspend or
      terminate the access of and take other action against users who infringe the copyright rights of others. If you
      believe that your work has been copied and is accessible on the Site in a way that constitutes copyright
      infringement, or that the Site contains links or other references to another online location that contains
      material or activity that infringes your copyright rights, you may notify Globusim by providing the following
      information (as required by the Online Copyright Infringement Liability Limitation Act of the Digital Millennium
      Copyright Act, 17 U.S.C. 512):</p>
    <p class="text-[15px] pb-1">- a physical or electronic signature of the person authorized to act on behalf of the
      owner of an exclusive right that is allegedly infringed;</p>
    <p class="text-[15px] pb-1">- identification of the copyrighted work claimed to have been infringed, or if multiple
      copyrighted works at a single online site are covered by a single notification, a representative list of such
      works at that site;</p>
    <p class="text-[15px] pb-1">- identification of the material that is claimed to be infringing or to be the subject
      of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably
      sufficient to permit Globusim to locate the material;</p>
    <p class="text-[15px] pb-1">- information reasonably sufficient to permit Globusim to contact the complaining party,
      such as an address, telephone number, and if available, an electronic mail address at which the complaining party
      may be contacted;</p>
    <p class="text-[15px] pb-6">- a statement that the complaining party has a good faith belief that use of the
      material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and a
      statement that the information in the notification is accurate, and under penalty of perjury, that the complaining
      party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</p>
    <p class="text-[15px] pb-6">The notification should be sent to Globusim's copyright agent:</p>
    <ul class="pb-6 pl-2">
      <li>011 Global Inc.</li>
      <li>2333 N SR7</li>
      <li>Margate, FL</li>
      <li>United States</li>
      <li>Phone: +1-877-800-9717</li>
    </ul>
    <h3 class="font-medium text-orange text-base">Endorsements</h3>
    <p class="text-[15px] pb-6">All product and service marks that appear on the Site and the Services that are not
      Globusim marks are the marks of their owners. References to any names, marks, products or services of third
      parties or hypertext links to third party sites or information do not constitute or imply endorsement, sponsorship
      or recommendation of the third party information, product or service.</p>
    <h3 class="font-medium text-orange text-base">Monitoring</h3>
    <p class="text-[15px] pb-6">You agree that Globusim has the right, but not the obligation, to monitor, edit,
      disclose, refuse to post, or remove at any time, for any reason in its sole discretion, any material and content
      anywhere on the Site or via the Services. Notwithstanding this right, Globusim does not and cannot review all
      materials posted by users on the Site or via the Services and Globusim is not responsible for any materials posted
      by users.</p>
    <h3 class="font-medium text-orange text-base">Submissions</h3>
    <p class="text-[15px] pb-6">All remarks, submissions, ideas, concepts, pictures or other information you submit to
      the Site or via the Services will become the exclusive property of Globusim. Globusim will not be required to
      treat any submission as confidential, and will be entitled to use all submissions for any purpose whatsoever,
      without any compensation to you.</p>
    <h3 class="font-medium text-orange text-base">Privacy</h3>
    <p class="text-[15px] pb-6">Globusim respects your privacy. For an explanation of our practices with regards to
      information collected or submitted to the Site or for the Services, please see our Privacy Policy online.</p>
    <h3 class="font-medium text-orange text-base uppercase">DISCLAIMER OF WARRANTIES</h3>
    <p class="text-[15px] pb-6 uppercase">THE SITE AND THE SERVICES, INCLUDING ALL CONTENT, FUNCTIONS, AND INFORMATION
      MADE AVAILABLE ON OR ACCESSED THROUGH THE SITE, ARE PROVIDED ON AN 'AS IS', 'AS AVAILABLE' BASIS WITHOUT
      REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION
      NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. GLOBUSIM DOES NOT WARRANT THAT THE SITE
      AND THE SERVICES, OR THE FUNCTIONS, FEATURES OR CONTENT CONTAINED THEREIN, WILL BE TIMELY, SECURE, UNINTERRUPTED
      OR ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
      WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO CERTAIN USERS.</p>
    <h3 class="font-medium text-orange text-base uppercase">LIMITATION OF LIABILITY</h3>
    <p class="text-[15px] pb-6 uppercase">IN NO EVENT SHALL GLOBUSIM BE LIABLE FOR ANY SPECIAL, INCIDENTAL OR
      CONSEQUENTIAL DAMAGES THAT ARE DIRECTLY OR INDIRECTLY RELATED TO THE USE OF, OR INABILITY TO USE, THE SITE, THE
      SERVICES, OR THE CONTENT, MATERIALS AND FUNCTIONS RELATED THERETO EVEN IF GLOBUSIM HAS BEEN ADVISED OF THE
      POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF LIABILITY FOR INCIDENTAL OR
      CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO CERTAIN USERS. IN NO EVENT SHALL THE TOTAL
      LIABILITY OF GLOBUSIM TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION (WHETHER IN CONTRACT OR TORT, INCLUDING
      BUT NOT LIMITED TO NEGLIGENCE) ARISING FROM THIS AGREEMENT OR YOUR USE OF THE SITE EXCEED ONE HUNDRED DOLLARS
      (US$100) IN THE AGGREGATE.</p>
    <h3 class="font-medium text-orange text-base">Indemnification</h3>
    <p class="text-[15px] pb-6">You agree to defend, indemnify and hold Globusim and its officers, directors, employees,
      agents, licensees, successors and assigns harmless from and against any and all claims, losses, damages,
      liabilities, costs and expenses (including reasonable attorneys' fees) incurred or suffered by them in connection
      with any claim arising out of or related to: (a) your use of the Site or the Services; (b) your breach or
      violation of any of these Terms of Use; (c) your dispute with another user; or (d) the unauthorized access to any
      password-protected area of the Site or the Services using your password.</p>
    <p class="text-[15px] pb-6">Globusim reserves the right, at its own expense, to assume the exclusive defense and
      control of any matter otherwise subject to indemnification by you, and you shall not in any event settle or
      otherwise dispose of any matter without the prior written consent of Globusim.</p>
    <h3 class="font-medium text-orange text-base">Termination</h3>
    <p class="text-[15px] pb-6">This agreement constitutes a binding agreement between you and Globusim until terminated
      by you or Globusim, which Globusim may do at any time, without notice, in its sole discretion. Without limiting
      the foregoing, reasons for termination may include Globusim's belief that: (a) you have breached the Terms of Use;
      (b) you are or may become insolvent or bankrupt; (c) an unauthorized person is or may be using your account; (d)
      you do not have any prepaid credit; (e) your use may be damaging or degrading the integrity of a third party
      network used by Globusim; (f) it is required to comply with a government request or order; (g) it is required to
      perform exceptional facilities' upgrades or maintenance; or (h) it is required to protect Globusim, the Site or
      the Services. If you become dissatisfied with the Site or the Services, your only recourse is to immediately
      discontinue use of the Site or the Services. ALL OUTSTANDING CHARGES WILL BECOME IMMEDIATELY DUE AND PAYABLE TO
      GLOBUSIM UPON NOTICE OF TERMINATION AND IN NO CIRCUMSTANCES SHALL YOU BE ENTITLED TO ANY REFUND OF PAYMENTS MADE.
    </p>
    <h3 class="font-medium text-orange text-base">Choice of Law; Forum</h3>
    <p class="text-[15px] pb-6">This agreement shall be governed by and in accordance with the laws of the State of
      Massachusetts, without regard to conflicts of law provisions. Any controversy involving Globusim arising from or
      in any way related to the Terms of Use or your use of the Site or the Services shall be heard in the appropriate
      State or Federal court in the City of Boston, Massachusetts and you irrevocably consent to the jurisdiction of
      such courts.</p>
    <h3 class="font-medium text-orange text-base">General Provisions</h3>
    <p class="text-[15px] pb-6">If any provision of the Terms of Use is held to be invalid or unenforceable by a court
      of competent jurisdiction, then such provision shall be enforced to the maximum extent possible so as to affect
      the intent of the Terms of Use, and the remainder of the Terms of Use shall continue in full force and effect. The
      failure by either you or Globusim to exercise or enforce any right or provision of the Terms of Use shall not
      constitute a waiver of such right or provision. A waiver is only effective in the specific instance and for the
      specific purpose for which it was given. You agree that any cause of action arising out of or related to the Site,
      the Services or the Terms of Use must commence within one (1) year after the cause of action arose; otherwise,
      such cause of action is permanently barred. You cannot assign your rights under the Terms of Use without
      Globusim's prior written approval. Except as expressly stated otherwise, the rights and remedies under these Terms
      of Use do not exclude any other right or remedy provided by law or in equity. The Terms of Use and all
      registration forms comprise the entire agreement between you and Globusim and supersedes all prior agreements
      between the parties, regarding the subject matter contained herein. The Terms of Use have been executed in the
      English language, which language will be controlling. No translation, if any, of the Terms of Use into any other
      language will have any effect on the interpretation of the Terms of Use or in determining the intent of the
      parties. All provisions in the Terms of Use regarding representations and warranties, indemnification,
      disclaimers, and limitation of liability shall survive the termination of the Terms of Use.</p>
  </section>
</main>