<main id="GetNumberPage" class="py-10">
  <section class="flex flex-col items-center px-5 mlg:flex-row justify-center gap-16 pb-10">
    <div class="w-full lg:w-[400px] smd:py-10">
      <article class="animate-fade-right px-6">
        <h2 class="py-5 leading-none" [innerHTML]="'get-your-number.title' | transloco"></h2>
        <p class=" text-grey pb-12 pr-6" transloco="get-your-number.description"></p>
      </article>

      <app-search-countries></app-search-countries>
    </div>

    <div>
      <img src="assets/images/icons/get-your-number/top1.png" alt="" class="w-fit">
    </div>

  </section>

  <section class="px-6 smd:px-20">
    <h2 class="text-center py-10 font-semibold" [innerHTML]="'get-your-number.quick-and-easy.title' | transloco"></h2>

    <div class="flex flex-col lg:flex-row items-center justify-center gap-10 py-10">
      <article class="relative shadow-full rounded-3xl px-6 pt-6 mb-auto max-w-[280px]">
        <img src="assets/images/icons/1vnumber.svg" alt="" class="w-16 absolute left-0 top-5">
        <h3 class="pt-10 h-44" transloco="get-your-number.quick-and-easy.card-1-title"></h3><hr>
        <img src="assets/images/icons/get-your-number/step1.svg" alt="">
      </article>
      <article class="relative shadow-full rounded-3xl px-6 pt-6 mb-auto max-w-[280px]">
        <img src="assets/images/icons/2vnumber.svg" alt="" class="w-16 absolute left-0 top-5">
        <h3 class="pt-10 h-44" transloco="get-your-number.quick-and-easy.card-2-title"></h3><hr>
        <img src="assets/images/icons/get-your-number/step2.svg" alt="">
      </article>
      <article class="relative shadow-full rounded-3xl px-6 pt-6 mb-auto max-w-[280px]">
        <img src="assets/images/icons/3vnumber.svg" alt="" class="w-16 absolute left-0 top-5">
        <h3 class="pt-10 h-44" transloco="get-your-number.quick-and-easy.card-3-title"></h3><hr>
        <img src="assets/images/icons/get-your-number/step3.svg" alt="">
      </article>
    </div>

    <!-- <div class="flex justify-center py-10">
      <app-btn-primary text="Get your number" link="/choose-your-number"></app-btn-primary>
    </div> -->
  </section>

</main>