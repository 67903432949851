import { Component, HostListener } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-scroll-to-top-btn',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './scroll-to-top-btn.component.html',
  styleUrls: ['./scroll-to-top-btn.component.scss']
})
export class ScrollToTopBtnComponent {
  showScrollButton = false;

  constructor(private viewportScroller: ViewportScroller) {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = this.viewportScroller.getScrollPosition()[1];
    this.showScrollButton = scrollPosition > 200; // Cambia 200 a la posición deseada para mostrar el botón
  }

  scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}