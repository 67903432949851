<main class="px-5 flex flex-col items-center">
  <section class="flex justify-center">
    <div class="flex flex-col w-fit py-12">
      <img src="assets/images/icons/personalInfo/shape.svg" alt="" class="w-20">
      <h2 class="text-center px-12 py-4 font-semibold" transloco="faq-vn.title"></h2>
      <img src="assets/images/icons/personalInfo/shape2.svg" alt="" class="w-32 ml-auto">
    </div>
  </section>
  <section class="lg:w-[946px]">
    <app-faq-accordion></app-faq-accordion>
  </section>
  <section class="flex flex-col items-center mb-16">
    <h2 class="text-center pt-10 pb-3 font-semibold" transloco="home.contact-us.title"></h2>
    <p class="text-grey text-center smd:pb-12" transloco="home.contact-us.subtitle"></p>
    <app-contact-form></app-contact-form>
  </section>
</main>
