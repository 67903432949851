import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { ApiResponse } from '../models/base/ApiResponse';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InquirysService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  async createSupportSubject<T>(dataCustomer: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.Inquiry}/support-customer`, dataCustomer)
    const data = await lastValueFrom(res)
    return data    
  }

  async deleteAccount<T>(dataCustomer: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.Inquiry}/cancel-customer`, dataCustomer)
    const data = await lastValueFrom(res)
    return data    
  }

  async contactUsInquiry<T>(dataCustomer: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.Inquiry}/create-lead`, dataCustomer)
    const data = await lastValueFrom(res)
    return data
  }
}
