import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { ReferralStatsComponent } from '../referral-stats/referral-stats.component';
import { FriendFormComponent } from '../friend-form/friend-form.component';

@Component({
  selector: 'app-free-credit',
  standalone: true,
  imports: [FriendFormComponent, ReactiveFormsModule, ReferralStatsComponent, TranslocoModule],
  templateUrl: './free-credit.component.html',
  styleUrl: './free-credit.component.scss'
})
export class FreeCreditComponent {
  formSubmitted: boolean = false
  referralForm: FormGroup;
  friends: { id: number; form: FormGroup }[] = [];

  constructor(private fb: FormBuilder) {
    this.referralForm = this.fb.group({});
    this.addFriend();
    this.addFriend();
  }

  addFriend() {
    const friendForm = this.fb.group({
      firstName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      dialCode: ['']
    });
    
    const newId = this.friends.length;
    this.friends.push({ id: newId, form: friendForm });
    this.referralForm.addControl(`friend${newId}`, friendForm);
  }

  onSubmit() {
    this.formSubmitted = true
    if (this.referralForm.valid) {
      this.formSubmitted = false
    }
  }
}
