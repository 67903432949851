import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../settings.service';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { IPackagePrice } from '../models/package-price.model';
import { ApiResponse } from '../models/base/ApiResponse';

@Injectable({
  providedIn: 'root',
})
export class PackagePriceService {
  constructor(private http: HttpClient, private settings: SettingsService) {}

  async getPackagePrice<T>(packageData) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.GetPackagePrice}`, packageData );
    const data = await lastValueFrom(res);
    return data;
  }
}
