<main class="flex flex-col items-center px-5 py-16">

  <img src="assets/images/logo.webp" alt="logo vnumber" class="w-72">

  <p transloco="login.recover-password"></p>
  <h3 class="text-center">{{ customerEmail }}</h3>

  <div class="flex flex-col items-center px-5 smd:px-24 py-8 border rounded-xl my-12 w-full md:max-w-xl">
    <span class="bg-orange w-28 h-28 rounded-full flex items-center justify-center mb-4">
      <img src="assets/images/icons/profile/user-2.svg" alt="" class="w-14">
    </span>
    @if (valid) {
      <img src="assets/images/icons/success.svg" alt="success icon" class="w-12">
      <span transloco="login.password-recovered">Password successfully recovered</span>
      <div>
        <span transloco="login.redirect">You will be redirected to login in </span>
        <span>{{ timer }}</span>
        <span transloco="login.seconds"> seconds</span>
      </div>
    }@else {
      <form action="" [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="w-full">
        <!-- ENTER NEW PASSWORD -->
        <div class="mb-4 ">
          <label for="newPassword" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm "
            transloco="form.enter-new-password"></label>
          <div class="relative">
  
            <input id="newPassword" [type]="showNewPassword ? 'text' : 'password'" formControlName="newPassword"
              [placeholder]="'form.password' | transloco"
              class="{{passwordForm.get('newPassword')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
            <button type="button" class="absolute right-3 top-1/2 transform -translate-y-1/2"
              (click)="this.showNewPassword = !this.showNewPassword">
              @if (!showNewPassword) {
                  <img src="assets/images/icons/login/eye-off.svg" alt="" class="w-5">
                } @else {
                  <img src="assets/images/icons/login/eye.svg" alt="" class="w-5">
                }
            </button>
          </div>
        </div>
        <!-- CONFIRM NEW PASSWORD -->
        <div class="mb-4">
          <label for="confirmPassword" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm "
            transloco="form.re-enter-new-password"></label>
          <div class="relative">
            <input id="confirmPassword" [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword"
              [placeholder]="'form.password' | transloco"
              class="{{passwordForm.get('confirmPassword')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
            <button type="button" class="absolute right-3 top-1/2 transform -translate-y-1/2"
              (click)="this.showConfirmPassword = !this.showConfirmPassword">
              @if (!showConfirmPassword) {
                <img src="assets/images/icons/login/eye-off.svg" alt="" class="w-5">
              } @else {
                <img src="assets/images/icons/login/eye.svg" alt="" class="w-5">
              }
            </button>
          </div>
  
          @if (passwordForm.invalid) {
          <div class="flex flex-col text-red">
            <small *ngIf="passwordForm.get('newPassword')?.errors?.['minlength'] && formSubmitted"
              transloco="form.password-character"></small>
            <small *ngIf="passwordForm.get('newPassword')?.errors?.['noUpperCase'] && formSubmitted"
              transloco="form.password-uppercase"></small>
            <small *ngIf="passwordForm.get('newPassword')?.errors?.['noNumber'] && formSubmitted"
              transloco="form.password-number"></small>
            <small *ngIf="passwordForm.get('newPassword')?.errors?.['noLetter'] && formSubmitted"
              transloco="form.password-uppercase"></small>
            <small
              *ngIf="passwordForm.errors?.['passwordMismatch'] && passwordForm.get('confirmPassword')?.touched"
              transloco="form.passwords-not-match"></small>
          </div>
          }
        </div>
        <!-- BUTTON -->
        <div class="flex flex-col items-center justify-between">
          <button type="submit" transloco="btn-vn.submit"
            class="bg-orange text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline"></button>
        </div>
      </form>
    }
  </div>
</main>