import { Component, Inject, inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { AuthService } from '../../../../services/auth/auth.service';
import { RouterModule } from '@angular/router';
import { PopUpService } from '@app/services/pop-up/pop-up.service';
import { LoadingService } from '@app/services/loading/loading.service';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, TranslocoModule],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss'
})
export class LoginFormComponent {
  authService = inject(AuthService);
  loadingService = inject(LoadingService);
  popUpService = inject(PopUpService);

  loginForm = this.formBuilder.group({
    username: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required]
  });

  formSubmitted: boolean = false;
  showPassword: boolean = false;

  constructor(private formBuilder: FormBuilder, @Inject(PLATFORM_ID) private platformId: Object) {}  

  async onSubmit() {
    this.formSubmitted = true
    this.loadingService.setLoading(true)

    try {
      if (this.loginForm.valid) {
        const res = await this.authService.login(this.loginForm.get('username').value, this.loginForm.get('password').value)
        
        if (res && this.authService.IsLogin()) {
          this.formSubmitted = false
          document.location.href = "/profile";
        }
      }
    } catch (error) {
      this.loadingService.setLoading(false);
      console.error(error);
      
      if (error.status === 401) {
        this.popUpService.openPopUp(error.error.messageCode.toLowerCase());
      }
    } finally {
      this.loadingService.setLoading(false);
    }
  }
}
