import { Injectable } from '@angular/core';
declare var grecaptcha: any;

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  constructor() { }

  executeRecaptcha(action: string): Promise<string> {
    return new Promise((resolve) => {
      (grecaptcha as any).ready(() => {
        (grecaptcha as any).execute('6Lc5BfwpAAAAACQ_HkjWGOEkHRErt10bp3NEloAv', { action: action }).then((token: string) => {
          resolve(token);
        });
      });
    });
  }
}