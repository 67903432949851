<div [formGroup]="formGroup" class="mb-4">
  <h3 class="text-sm font-semibold pb-8 text-orange">#{{formNumber}} friend</h3>
  <div class="flex flex-col mb-4 w-full">
    <label for="firstName" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.first-name"></label>
    <input type="text" id="firstName" formControlName="firstName" [placeholder]="'form.first-name' | transloco"
      class="{{formGroup.get('firstName')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
  </div>
  <!-- EMAIL -->
  <div class="flex flex-col mb-4 w-full">
    <label for="email" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm " transloco="form.email"></label>
    <input type="email" id="email" formControlName="email" [placeholder]="'form.email' | transloco"
      class="{{formGroup.get('email')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
  </div>
  <div class="flex flex-col mb-4 w-full">
    <label for="phoneInput" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.phone"></label>
    <input type="tel" id="phoneInput" formControlName="phone" #phoneInput
      class="{{formGroup.get('phone')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
  </div>
</div>