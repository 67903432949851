import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-advantages-card',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './advantages-card.component.html',
  styleUrl: './advantages-card.component.scss'
})
export class AdvantagesCardComponent {
  @Input() position: string
  @Input() title: string
  @Input() description: string
}
