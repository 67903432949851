<main class="relative px-5 pb-16">

  <section class="flex justify-center">
    <div class="flex flex-col w-fit py-12">
      <img src="assets/images/icons/personalInfo/shape.svg" alt="" class="w-20">
      <h2 class="text-center px-12 py-4 font-semibold" transloco="personal-info.title"></h2>
      <img src="assets/images/icons/personalInfo/shape2.svg" alt="" class="w-32 ml-auto">
    </div>
  </section>

  <section class="flex justify-center">
    <form [formGroup]="personalInfoForm" (ngSubmit)="onSendCodeSubmit(1)" class="flex flex-col items-center w-full lg:w-[60%]">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-10 py-8 w-full">
        <!-- FIRST NAME -->
        <div class="mb-4 w-full">
          <label for="firstName" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.first-name"></label>
          <input type="text" id="firstName" formControlName="firstName" [placeholder]="'form.first-name' | transloco"
            class="{{personalInfoForm.get('firstName')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
        </div>
        <!-- LAST NAME -->
        <div class="mb-4 w-full">
          <label for="lastName" class="block text-sm font-medium text-gray-700" transloco="form.last-name"></label>
          <input type="text" id="lastName" formControlName="lastName" [placeholder]="'form.last-name' | transloco"
            class="{{personalInfoForm.get('lastName')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
        </div>
        <!-- EMAIL -->
        <div class="mb-4 w-full">
          <label for="email" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.email"></label>
          <input type="email" id="email" formControlName="email" [placeholder]="'form.email' | transloco"
            class="{{personalInfoForm.get('email')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
        </div>
        <!-- PASSWORD -->
        <div class="relative mb-6 w-full">
          <label for="password" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.password"></label>
          <input [type]="showPassword ? 'text' : 'password'" id="password" formControlName="password" autocomplete="password" [placeholder]="'form.password' | transloco"
            class="{{personalInfoForm.get('password')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
            <button type="button" class="absolute right-3 top-11 transform -translate-y-1/2" (click)="this.showPassword = !this.showPassword">
              @if (!showPassword) {
                <img src="assets/images/icons/login/eye-off.svg" alt="" class="w-5">
              } @else {
                <img src="assets/images/icons/login/eye.svg" alt="" class="w-5">
              }
            </button>
            <div class="flex flex-col text-sm text-danger">
            <span *ngIf="personalInfoForm.get('password')?.errors?.['minlength'] && formSubmitted" transloco="form.password-character"></span>
            <span *ngIf="personalInfoForm.get('password')?.errors?.['noUpperCase'] && formSubmitted" transloco="form.password-uppercase"></span>
            <span *ngIf="personalInfoForm.get('password')?.errors?.['noNumber'] && formSubmitted" transloco="form.password-number"></span>
            <span *ngIf="personalInfoForm.get('password')?.errors?.['noLetter'] && formSubmitted" transloco="form.password-uppercase"></span>
          </div>
        </div>
        <!-- PHONE -->
        <div class="flex flex-col mb-4 w-full">
          <label for="phoneInput" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.local-mobile"></label>
          <input type="tel" id="phoneInput" formControlName="phone" #phoneInput
            class="{{personalInfoForm.get('phone')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full lg:w-[346px] rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
        </div>
      </div>

      <!-- INFO -->
      <div class="flex flex-col smd:flex-row items-center p-6 shadow-full rounded-3xl">
        <img src="assets/images/icons/personalInfo/info.svg" alt="info icon" class="w-16">
        <p class="text-grey px-6" transloco="personal-info.only-use-numbers"></p>
      </div>

      <!-- CHECKBOX  -->
      <div class="flex items-center justify-between py-8">
        <div class="block pl-6">
          <input
            class="{{ formSubmitted && !isChecked ? 'border-danger': 'border-greyDark'}} relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded border-[0.125rem] border-solid  outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-orange checked:bg-orange checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
            type="checkbox" value="" id="checkPermissions" (change)="checkPermissions(!isChecked)"
            [checked]="isChecked" />
          <label class="inline-block lg:pl-4 hover:cursor-pointer text-[10px] text-grey" for="checkPermissions" transloco="form.check-permission"></label>
        </div>
      </div>

      <!-- SEND SMS BUTTON -->
      <div *ngIf="!showConfirmation && !customerExist" class="py-8">
        @if (isLoading) {
          <app-spinner-small></app-spinner-small>
        } @else {
          <button type="submit"
            class="{{ isChecked && personalInfoForm.valid ? 'transition delay-75 duration-200 hover:scale-110' : 'opacity-60'}} flex bg-orange items-center text-white font-semibold uppercase rounded-full px-9 py-3 w-max "
            transloco="btn-vn.send"></button>
        }
      </div>
    </form>
  </section>

  @if(customerExist) {
    <div class="flex flex-col items-center pt-12 pb-32">
      <p class="text-center pb-6 text-red" transloco="personal-info.already-user"></p>
      <app-btn-login></app-btn-login>
    </div>
  }

  @if(showConfirmation) {
    <div class="flex flex-col justify-center">
      <p class="text-center text-grey font-medium mb-4" transloco="personal-info.enter-code"></p>

      <div class="code-input flex justify-center gap-2">
        <input type="text" maxlength="1" (input)="onInputChange($event, 0)" #confirmationInput>
        <input type="text" maxlength="1" (input)="onInputChange($event, 1)" #confirmationInput>
        <input type="text" maxlength="1" (input)="onInputChange($event, 2)" #confirmationInput>
        <input type="text" maxlength="1" (input)="onInputChange($event, 3)" #confirmationInput>
        <input type="text" maxlength="1" (input)="onInputChange($event, 4)" #confirmationInput>
        <input type="text" maxlength="1" (input)="onInputChange($event, 5)" #confirmationInput>
      </div>

      <img src="assets/images/icons/personalInfo/dialogue.svg" alt="" class="w-5 mx-auto mt-4">
      <div class="flex justify-center text-sm text-gray-600">
        <p *ngIf="resendTimer > 0">
          <span transloco="btn-vn.resend"></span> 
          <span>
            | {{resendTimer | number:'2.0-0'}}
          </span>
        </p>
        <button *ngIf="resendTimer === 0" (click)="resendSMS()" class="" transloco="btn-vn.resend"></button>
      </div>

      <div class="flex justify-center py-16">
        <button type="button" [disabled]="!allInputsComplete" (click)="onConfirmationSubmit()"
          class="{{ allInputsComplete ? ' transition delay-75 duration-200 hover:scale-110' : ''}} flex bg-orange items-center text-white font-semibold uppercase rounded-full px-16 py-3 w-max"
          transloco="btn-vn.check"></button>
      </div>
    </div>
  }
  <img src="assets/images/icons/personalInfo/hand.svg" alt="shop hand"
    class="mx-auto pb-12 lg:pb-0 lg:absolute lg:bottom-6 lg:ltr:right-20 lg:rtl:left-20 rtl:-scale-x-100 w-64">

    <!-- <div *ngIf="isDevelopmentOrPreProd" class="debug-controls mt-4">
      <button 
        class="bg-red p-2 rounded-xl text-white"
        (click)="debugNavigateToPayment()">
        Debug: Skip to Payment Page
      </button>
      <small class="text-muted d-block mt-2">
        This button is only visible in development/pre-production environments
      </small>
    </div> -->
</main>