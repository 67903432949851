<section class="rounded-xl shadow-full border border-greyLight py-8 px-6 md:px-16 w-full">
  <h2 class="flex flex-col text-2xl mb-6" [innerHTML]="'profile-vn.add-credit.title' | transloco"></h2>

  <article class="">
    <h3 class="text-base font-medium pt-4" transloco="profile-vn.add-credit.subtitle"></h3>

    <div class="flex flex-wrap gap-4 py-12">
      @for (amount of ['15', '25', '50']; track $index) {
      <button (click)="selectAmount(amount)" [class]="{'border-orange': customAmount === amount}"
        class=" flex items-center btn-effect text-green py-2 px-6 sm:px-10 border rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 focus:outline-none focus:ring-1 focus:ring-orange">
        <span class="text-lg font-semibold">$ {{amount}}</span>
      </button>
      }
      <div class="relative flex items-center text-green text-lg font-semibold ">
        <input type="text" [(ngModel)]="customAmount" placeholder="-" maxlength="2" (input)="numberOnly($event);"
          class="w-24 py-3 ltr:pl-11 rtl:pr-11 font-semibold bg-greyLight rounded-xl shadow-sm focus:outline-none focus:ring-1 focus:ring-orange">
        <span class="absolute left-7">$</span>
      </div>
    </div>

    <div [class.opacity-20]="customAmount === ''" [class.pointer-events-none]="customAmount === ''">
      @if (savedCards.length > 0) {
      <div>
        <h2 class="flex flex-col text-2xl mb-6 font-semibold" transloco="payment.use-saved-methods"></h2>
        <app-credit-card [savedCards]="savedCards"
          (selectedPaymentId)="onSelectedPaymentMethod($event)"></app-credit-card>
      </div>
      }

      <div class="pb-12">
        @if (savedCards.length > 0) {
        <h2 class="flex flex-col text-2xl my-6 font-semibold" transloco="payment.add-new-method"></h2>
        }
        <button type="button" (click)="showCreditCardForm()"
          class="flex items-center justify-center gap-1 text-orange font-semibold border-2 border-orange w-full sm:w-fit px-6 sm:px-10 py-3 rounded-xl hover:scale-105">
          <span transloco="btn-vn.add-payment-method"></span>
          <img src="assets/images/icons/arrow-orange.svg" alt="orange arrow"
            class="w-[1.8rem] pb-1 h-fit rtl:-scale-x-100">
        </button>
      </div>
    </div>

    <button (click)="addCredit()" [disabled]="shouldDisableButton()"
      class="{{ shouldDisableButton() ? 'opacity-50' : 'hover:scale-105 transition duration-200' }} w-full bg-orange text-white uppercase font-semibold py-3 px-4 rounded-lg  "
      transloco="btn-vn.add-credit"></button>

  </article>

</section>

@if (showCreditCard) {
<app-payment-methods [isAddCredit]="true" [editingCardId]="null" [amountToAdd]="customAmount"
  (paymentUpdated)="onAddCreditFromNewCard()" (closeModalEvent)="showCreditCard = false">
</app-payment-methods>
}

@if (showConfirmationPopUp) {
<div
  class="fixed inset-0 bg-white bg-opacity-75 flex flex-col items-center z-50 transition-opacity duration-500 ease-in-out scrollbar-sec overflow-y-auto">
  <span (click)="onCloseConfirmationPopUp()"
    class="absolute right-2 top-2 mt-16 sm:mt-20 cursor-pointer active:scale-90 bg-white rounded-full z-10">
    <img src="assets/images/icons/close-popup.svg" alt="" class="w-8 sm:w-12"></span>

  <div class="flex flex-col items-center my-auto">
    <div class="bg-white rounded-xl px-12 py-6 border-2 border-orange">
      <section class="flex justify-center">
        <div class="flex flex-col w-fit pb-6">
          <img src="assets/images/icons/plans/5vnumber.svg" alt="" class="w-12 ml-auto">
          <h3 class="text-center px-12 py-4 font-semibold" transloco="profile-vn.add-credit.done-title"></h3>
          <img src="assets/images/icons/FAQ_1vnumber.svg" alt="" class="w-20 ">
        </div>
      </section>
      <section class="flex">
        <img src="assets/images/icons/cel-hand.svg" alt="" class="w-36">
        <article>
          <div class="py-2">
            <span class="" transloco="profile-vn.add-credit.recharged-amount"></span>
            <div class="flex gap-1">
              <img src="assets/images/icons/profile/positive-balance.svg" alt="icon" class="w-8">
              <span class="text-green text-2xl font-medium">$ {{ customAmount }}</span>
            </div>
          </div>

          <div class="flex gap-2">
            <span transloco="profile-vn.add-credit.confirmation-number"></span>
            <span>{{ confirmationID }}</span>
          </div>
        </article>
      </section>
    </div>
  </div>
</div>
}