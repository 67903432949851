import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneFormat',
  standalone: true,
})
export class PhoneFormatPipe implements PipeTransform {
  transform(value: string, mask: string, prefix: string): string {
    if (!value) return value;

    // Remove all non-numeric characters from the input value
    let cleanedNumber = value.replace(/\D/g, '');

    // Verificar si el número ya incluye el prefijo
    if (prefix && cleanedNumber.startsWith(prefix)) {
      cleanedNumber = cleanedNumber.slice(prefix.length);
    }

    // If there is no mask, we just return the number with the prefix
    if (!mask) {
      return prefix ? `+(${prefix}) ${cleanedNumber}` : cleanedNumber;
    }

    // Replace hyphens with spaces in the mask
    const cleanedMask = mask.replace(/-/g, ' ');

    let result = '';
    let numberIndex = 0;

    // Apply the mask to the number
    for (let i = 0; i < cleanedMask.length && numberIndex < cleanedNumber.length; i++) {
      if (cleanedMask[i] === 'x') {
        result += cleanedNumber[numberIndex];
        numberIndex++;
      } else {
        result += cleanedMask[i];
      }
    }

    // Add the prefix if it exists
    return prefix ? `+(${prefix}) ${result.trim()}` : result.trim();
  }
}