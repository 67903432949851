import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CountriesService } from '@app/services/countries/countries.service';
import { ICountries } from '@app/services/models/countries.model';
import { ICreditCardInfo } from '@app/services/models/credit-card-info.model';
import { IState } from '@app/services/models/states.model';
import { PaymentMethodsService } from '@app/services/payment-methods/payment-methods.service';
import { StatesService } from '@app/services/states/states.service';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { SpinnerComponent } from '../../../loading/spinner/spinner.component';
import { SpinnerSmallComponent } from '@app/core/shared/loading/spinner-small/spinner-small.component';
import { RecaptchaService } from '@app/services/recatpcha/recaptcha.service';
import { PopUpService } from '@app/services/pop-up/pop-up.service';

@Component({
  selector: 'app-payment-methods',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SpinnerComponent,
    SpinnerSmallComponent,
    TranslocoModule,
  ],
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
})
export class PaymentMethodsComponent {
  countriesService = inject(CountriesService);
  paymentMethodsService = inject(PaymentMethodsService);
  statesService = inject(StatesService);
  translateService = inject(TranslocoService);
  recaptchaService = inject(RecaptchaService);
  popUpService = inject(PopUpService);

  @ViewChild('cardNumberInput') cardNumberInput!: ElementRef;

  @Input() isEditing: boolean = false;
  @Input() isAddCredit: boolean = false;
  @Input() editingCardId: number;
  @Input() amountToAdd: string;

  @Output() paymentUpdated: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeModalEvent: EventEmitter<void> = new EventEmitter<void>();

  showRemoveCardPopUp: boolean = false;
  showAddressForm: boolean = false;
  flipped: boolean = false;
  formSubmitted: boolean = false;
  isLoading: boolean = false;
  isSuccessfull: boolean = false;

  yearsToShow: number[] = [];
  currentYear: number = new Date().getFullYear();
  months: string[] = Array.from({ length: 12 }, (_, i) =>
    this.formatNumberOfMonth(i + 1)
  );

  countries: ICountries[] = [];
  editingCard: ICreditCardInfo | null = null;
  states: IState[] = [];

  cardType: string = '';
  selectedCountryId: string = '';
  selectedState: string = '';
  currentLang: string = '';
  selectedMonth: string = '';

  selectedYear: number = 0;

  creditCardForm = this.paymentMethodsService.paymentMethodsForm.get(
    'creditCardFrom'
  ) as FormGroup;
  addressCardForm = this.paymentMethodsService.paymentMethodsForm.get(
    'addressCardForm'
  ) as FormGroup;

  constructor() {
    this.generateYearsOption();
  }

  ngOnInit(): void {
    this.getCountries();
    this.getCurrentLang();

    this.creditCardForm.get('cardExpirationMM')?.valueChanges.subscribe(month => {
      this.selectedMonth = month;
      this.updateAvailableYears();

      if (this.selectedYear === this.currentYear) {
        const currentMonth = new Date().getMonth() + 1;
        if (parseInt(month) < currentMonth) {
          // If the selected month is less than the current month, we clear the year
          this.creditCardForm.get('cardExpirationYY')?.setValue('');
        }
      }
    });

    this.creditCardForm.get('cardExpirationYY')?.valueChanges.subscribe(year => {
      this.selectedYear = year;
      this.updateAvailableMonths();

      
      // If you select the current year, we validate the month
      if (year === this.currentYear) {
        const currentMonth = new Date().getMonth() + 1;
        if (parseInt(this.selectedMonth) < currentMonth) {
          // If the month is less than the current month, we clean it
          this.creditCardForm.get('cardExpirationMM')?.setValue('');
        }
        // We update the available months
        this.months = Array.from(
          { length: 12 - currentMonth + 1 }, 
          (_, i) => this.formatNumberOfMonth(currentMonth + i)
        );
      } else {
        // If it is a future year, we show all the months
        this.months = Array.from(
          { length: 12 }, 
          (_, i) => this.formatNumberOfMonth(i + 1)
        );
      }
    });

    this.addressCardForm
      .get('country')
      ?.valueChanges.subscribe((selectedCountry: string) => {
        this.selectedCountryId = selectedCountry;
      });

    if (this.isEditing && this.editingCardId) {
      this.getPaymentMethod();
    } else {
      // this.cardNumberInput?.nativeElement.focus();
      this.creditCardForm
        .get('cardNumber')
        ?.valueChanges.subscribe((cardNum: string) => {
          this.cardType = this.detectCardType(cardNum);
        });

      this.creditCardForm
        .get('cardNumber')
        ?.setValidators([
          Validators.required,
          Validators.minLength(13),
          Validators.maxLength(16),
          Validators.pattern(/^\d{13,16}$/),
        ]);

      this.creditCardForm.get('cardNumber')?.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.creditCardForm.setValue({
      cardHolder: '',
      cardNumber: '',
      cardExpirationMM: '',
      cardExpirationYY: '',
      cardCVV: '',
      isPrimaryCard: false,
      keepCard: true,
      termsAndConditions: true,
    });

    this.addressCardForm.setValue({
      address: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
    });

    this.cardType = '';
    this.showAddressForm = false;
  }

  getCurrentLang() {
    // Subscribe to language changes
    this.translateService.langChanges$.subscribe((lang: string) => {
      this.currentLang = lang; // Update currentLang when language changes
    });

    // Initialize currentLang with the current active language
    this.currentLang = this.translateService.getActiveLang();
  }

  formatNumberOfMonth(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  updateAvailableMonths(): void {
    const currentMonth = new Date().getMonth() + 1; // getMonth() return 0-11
    
    if (this.selectedYear === this.currentYear) {
      // If the selected year is the current year, display only months since the current year.
      this.months = Array.from(
        { length: 12 - currentMonth + 1 }, 
        (_, i) => this.formatNumberOfMonth(currentMonth + i)
      );
    } else {  // If it is a future year, show all months
    
      this.months = Array.from(
        { length: 12 }, 
        (_, i) => this.formatNumberOfMonth(i + 1)
      );
    }

    // If the selected month is no longer in the available list, clear it.
    if (this.selectedMonth && !this.months.includes(this.selectedMonth)) {
      this.creditCardForm.get('expiryMonth')?.setValue('');
    }
  }

  updateAvailableYears(): void {
    const currentMonth = new Date().getMonth() + 1;
    const selectedMonthNum = parseInt(this.selectedMonth);
    
    if (selectedMonthNum < currentMonth) { // If the selected month is prior to the current month
      this.yearsToShow = this.yearsToShow.filter(year => year > this.currentYear);  // Filter the current year of the options
      
      if (this.selectedYear === this.currentYear) {  // If the current year is selected, clear it
        this.creditCardForm.get('expiryYear')?.setValue('');
      }
    } else { // Regenerate complete list of years if necessary
      this.generateYearsOption();
    }
  }

  generateYearsOption(): void {
    const yearsCount = 10;
    this.yearsToShow = Array.from(
      { length: yearsCount }, 
      (_, i) => this.currentYear + i
    );
  }

  closeCCModal() {
    this.closeModalEvent.emit();
  }

  flipCard() {
    this.flipped = !this.flipped;
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeCCModal();
    }
  }

  onSubmitCreditCard(): void {
    this.formSubmitted = true;

    if (this.creditCardForm.valid) {
      this.showAddressForm = true;
      this.formSubmitted = false;
    }
  }

  onSubmitAddressCard(): void {
    if (this.addressCardForm.valid) {
      if (this.isEditing) {
        this.isLoading = true;
        this.editPaymentMethod();
      } else if (this.isAddCredit) {
        this.paymentUpdated.emit();
      } else {
        this.isLoading = true;
        this.addPaymentMethod();
      }
    }
  }

  detectCardType(cardNumber: string): string {
    // Remove all non-digit characters from the card number
    const cleanNumber = cardNumber.replace(/\D/g, '');

    if (/^4/.test(cleanNumber)) return 'visa'; // Visa
    if (/^(5[1-5]|2[2-7])/.test(cleanNumber)) return 'mastercard'; // Mastercard
    if (/^3[47]/.test(cleanNumber)) return 'american-express'; // American Express
    if (/^3(0[0-5]|[68])/.test(cleanNumber)) return 'diners'; // Diners Club
    if (/^6(?:011|5)/.test(cleanNumber)) return 'discover'; // Discover
    return ''; // If no match is found
  }

  onCountryChange(event: Event) {
    const countryCode = Number((event.target as HTMLSelectElement).value);
    this.getStates(countryCode);
  }

  onClickRemoveCard() {
    this.showRemoveCardPopUp = true;
  }

  goBack(): void {
    this.showAddressForm = false;
  }

  async getCountries() {
    const res = await this.countriesService.getCountrySelection<ICountries[]>();

    if (res.isError) {
      console.error('ERROR: ', res.errorMessage);
      return;
    }

    if (res.isSuccessful) {
      this.countries = res.result;

      if (this.countries.length > 0) {
        if (this.isEditing && this.editingCard) {
          const addressCard = this.editingCard?.address;
          this.selectedCountryId = addressCard.countryID;
          this.addressCardForm.get('country')?.setValue(this.selectedCountryId);
        } else {
          this.selectedCountryId = this.currentLang === 'he' ? '101' : '218';
          this.addressCardForm.get('country')?.setValue(this.selectedCountryId);
        }

        this.getStates(Number(this.selectedCountryId));
      }
    }
  }

  async getStates(countryId: number) {
    const res = await this.statesService.getStates<IState[]>(countryId);

    if (res.isSuccessful) {
      this.states = res.result;

      if (this.isEditing && this.editingCard) {
        const addressCard = this.editingCard?.address;
        this.addressCardForm.get('state')?.setValue(addressCard.stateID);
      }
    }
  }

  async getPaymentMethod() {
    try {
      const res =
        await this.paymentMethodsService.getCreditCardById<ICreditCardInfo>(
          this.editingCardId
        );

      if (res.isSuccessful && res.result) {
        this.editingCard = res.result;
        this.cardType = this.editingCard?.creditCardType;
        const addressCard = this.editingCard?.address;

        this.creditCardForm.get('cardNumber')?.clearValidators();
        this.creditCardForm.get('cardNumber')?.updateValueAndValidity();
        this.creditCardForm.get('cardCVV')?.clearValidators();
        this.creditCardForm.get('cardCVV')?.updateValueAndValidity();

        const formattedExpirationMonth =
          this.editingCard.expirationMonth.length === 1
            ? '0' + this.editingCard.expirationMonth
            : this.editingCard.expirationMonth;

        this.creditCardForm.patchValue({
          cardHolder: this.editingCard.cardHolder,
          cardNumber: '•••• •••• •••• ' + this.editingCard.lastFourNumbers,
          cardExpirationMM: formattedExpirationMonth,
          cardExpirationYY: this.editingCard.expirationYear,
          isPrimaryCard: this.editingCard.isPrimary,
        });

        this.addressCardForm.patchValue({
          address: addressCard.address,
          city: addressCard.city,
          zipCode: addressCard.zipCode,
          country: addressCard.countryID,
          state: addressCard.state,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async editPaymentMethod() {
    const { cardNumber, cardHolder, cardExpirationMM, cardExpirationYY, cardCVV, isPrimaryCard } = this.creditCardForm.value
    const token = await this.recaptchaService.executeRecaptcha('editCreditCard')

    const jsonData = {
      CreditCardNumber: cardNumber,
      PaymentId: this.editingCard.creditCardId.toString(),
      CardHolder: cardHolder,
      ExpirationMonth: Number(cardExpirationMM),
      ExpirationYear: Number(cardExpirationYY),
      SecurityCode: cardCVV,
      IsPrimary: isPrimaryCard ? 1 : 0,
      City: this.addressCardForm.get('city').value,
      State: this.addressCardForm.get('state').value,
      Zip: this.addressCardForm.get('zipCode').value,
      Address: this.addressCardForm.get('address').value,
      CountryNumber: this.addressCardForm.get('country').value.toString(),
      IsSaveCard: 1, // always true in this scenario
      recaptcha: token
    };

    try {
      const res = await this.paymentMethodsService.editSavedCreditCard(
        jsonData
      );

      if (res.response_code === 200) {
        this.paymentUpdated.emit();
        this.isSuccessfull = true;
        setTimeout(() => {
          this.closeCCModal();
          this.isSuccessfull = false;
        }, 1500);
      } else {
        this.popUpService.openPopUp('server-error');
      }
    } catch (error) {
      console.error(error);
      this.popUpService.openPopUp('server-error');
    } finally {
      this.isLoading = false;
    }
  }

  async addPaymentMethod() {
    const { cardNumber, cardHolder, cardExpirationMM, cardExpirationYY, cardCVV, isPrimaryCard } = this.creditCardForm.value
    const token = await this.recaptchaService.executeRecaptcha('addCreditCard')

    const jsonData = {
      CreditCardNumber: cardNumber,
      CardHolder: cardHolder,
      ExpirationMonth: Number(cardExpirationMM),
      ExpirationYear: Number(cardExpirationYY),
      SecurityCode: cardCVV,
      IsPrimary: isPrimaryCard ? 1 : 0,
      City: this.addressCardForm.get('city').value,
      State: this.addressCardForm.get('state').value,
      Zip: this.addressCardForm.get('zipCode').value,
      Address: this.addressCardForm.get('address').value,
      CountryNumber: this.addressCardForm.get('country').value.toString(),
      IsSaveCard: 1, // always true in this scenario
      recaptcha: token
    };
    // const jsonData = {
    //   creditCardNumber: this.creditCardForm.get('cardNumber').value,
    //   cardHolder: this.creditCardForm.get('cardHolder').value,
    //   expirationMonth: this.creditCardForm.get('cardExpirationMM').value,
    //   expirationYear: this.creditCardForm.get('cardExpirationYY').value,
    //   securityCode: this.creditCardForm.get('cardCVV').value,
    //   isPrimary: this.creditCardForm.get('isPrimaryCard').value,
    //   address: {
    //     countryID: this.addressCardForm.get('country').value,
    //     city: this.addressCardForm.get('city').value,
    //     state: this.addressCardForm.get('state').value,
    //     zipCode: this.addressCardForm.get('zipCode').value,
    //     address: this.addressCardForm.get('address').value,
    //     stateID: this.addressCardForm.get('state').value,
    //   },
    // };

    try {
      const res = await this.paymentMethodsService.createCreditCard(jsonData);

      if (res.response_code === 200) {
        this.paymentUpdated.emit();
        this.isSuccessfull = true;
        setTimeout(() => {
          this.closeCCModal();
          this.isSuccessfull = false;
        }, 2000);
      } else {
        this.popUpService.openPopUp('server-error');
      }
    } catch (error) {
      console.error(error);
        this.popUpService.openPopUp('server-error');
    } finally {
      this.isLoading = false;
    }
  }

  async removePaymentMethod(cardId: number) {
    try {
      const res = await this.paymentMethodsService.deleteCreditCardById(cardId);

      if (res.isSuccessful && res.result) {
        this.paymentUpdated.emit();
        this.closeCCModal();
      }
    } catch (error) {}
  }
}
