import { Component, Input, NgZone } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { CommonModule } from '@angular/common';

interface items {
  position: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-card-carousel',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './card-carousel.component.html',
  styleUrls: ['./card-carousel.component.scss'],
})
export class CardCarouselComponent {
  @Input() position: string;
  @Input() title: string;
  @Input() description: string;
  currentIndex: number = 0;
  intervalId: any;
  items: items[] = [
    { position: '1', title: 'card-1-title', description: 'card-1-text' },
    { position: '2', title: 'card-2-title', description: 'card-2-text' },
    { position: '3', title: 'card-3-title', description: 'card-3-text' },
    { position: '4', title: 'card-4-title', description: 'card-4-text' },
    { position: '5', title: 'card-5-title', description: 'card-5-text' },
    { position: '6', title: 'card-6-title', description: 'card-6-text' },
  ];

  constructor(private ngZone: NgZone) {}
  ngOnInit(): void {
    this.startCarousel();
  }

  ngOnDestroy(): void {
    this.stopCarousel();
  }

  startCarousel(): void {
    this.ngZone.runOutsideAngular(() => {
      this.intervalId = setInterval(() => {
        this.next();
      }, 5000);
    });
  }

  stopCarousel(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  next(): void {
    this.currentIndex = (this.currentIndex + 1) % this.items.length;
  }

  prev(): void {
    this.currentIndex =
      (this.currentIndex - 1 + this.items.length) % this.items.length;
  }

  goToSlide(index: number): void {
    this.currentIndex = index;
  }
}
