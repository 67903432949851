import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-spinner-small',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner-small.component.html',
  styleUrls: ['./spinner-small.component.scss']
})
export class SpinnerSmallComponent {

}
