import { Component } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { LoginFormComponent } from 'src/app/core/shared/login/login-form/login-form.component';
import { LoginSocialMediaComponent } from 'src/app/core/shared/login/login-social-media/login-social-media.component';


@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [TranslocoModule, LoginFormComponent, LoginSocialMediaComponent],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss'
})
export class LoginPageComponent {

}
