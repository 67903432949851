import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { ApiResponse } from '../models/base/ApiResponse';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallerIdService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  async getCallerIds<T>() {
    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.GetCallerID}`)
    const data = await lastValueFrom(res)
    return data
  }

  async updateCLINumber<T>(cliData: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.UpdateCLINumber}`, cliData)
    const data = await lastValueFrom(res);
    return data
  }

  async addCLINumber<T>(cliData: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.AddCLINumber}`, cliData )
    const data = await lastValueFrom(res);
    return data
  }

  async deleteCLINumber<T>(cliData: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.DeleteCLINumber}`, { id: cliData })
    const data = await lastValueFrom(res);
    return data
  }
}
