<main class="p-10 md:px-16 mlg:px-36">
  <section class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] py-16 animate-fade-down">
    <h2 class="text-center text-4xl font-semibold">Privacy policy</h2>
  </section>

  <section class="animate-fade-up">
    <h3 class="font-medium text-orange text-base">What is Privacy?</h3>
    <p class="text-[15px] pb-6">Information privacy is about giving individuals some control over when, how and to what extent information about themselves is communicated to others.</p>
    <p class="text-[15px] pb-6">Globusim, the provider of the Globusim service, is committed to providing you with privacy safeguards that are consistent with your right to choose whether you wish to provide information about yourself, and if you do provide it, the nature and quality of that information. By using the website and services, you agree to the collection and use of your information in accordance with this Privacy Policy. If you do not agree with this Privacy Policy, please do not use the website or the services.</p>

    <h3 class="font-medium text-orange text-base">Collection</h3>
    <p class="text-[15px] pb-6">Globusim will collect personally identifiable information from you that is necessary for the purposes of supplying to you the services you subscribe for, such as name, postal address, email address, and telephone number. Globusim may also automatically collect your travel location based on the cell tower locations used to transmit your mobile phone call or local country access numbers dialed to use your phonecard.</p>
    <p class="text-[15px] pb-6">The website and the services are not intended for use by children. Globusim will not knowingly collect any personally identifiable information from users under the age of 13.</p>
    
    <h3 class="font-medium text-orange text-base">Use and Disclosure</h3>
    <p class="text-[15px] pb-6">Globusim will use your personally identifiable information for customer service, to provide you with information that you may request, and to customize your experience on the website or with the services. Your personally identifiable information is used by Globusim to respond to your requests, process your transactions, tailor offerings to you, and for general internal buisness purposes.</p>
    <p class="text-[15px] pb-6">Globusim will use your financial information to bill you for the products or otherwise process your transactions. By making a purchase on the website or using the services, you consent to Globusim providing your financial information to its service providers and to such third parties as it determines is necessary to process your transactions. These third parties may include the credit card companies and banking institutions used to process the transaction.</p>
    <p class="text-[15px] pb-6">If Globusim is sold to, or merges with another company or declares bankruptcy, some or all of the information collected from you may be transferred to a third party as a result of assets. Globusim may also disclose your information when Globusim determines it is necessary to applicable laws or to protect the interests or safety of Globusim or other website or service users.</p>
    
    <h3 class="font-medium text-orange text-base">Quality of Data</h3>
    <p class="text-[15px] pb-6">Globusim will take reasonable steps to ensure that the information we collect from you, use or disclose is accurate, complete and up-to-date.</p>
    <h3 class="font-medium text-orange text-base">Data Security</h3>
    <p class="text-[15px] pb-6">Globusim will take all reasonable steps to ensure that the personal information we hold about you is protected from misuse and loss or from unauthorized access, modification, or disclosure. Globusim will also take reasonable steps to destroy or de-identify your personal information when we no longer need it.</p>
    <h3 class="font-medium text-orange text-base">Openness</h3>
    <p class="text-[15px] pb-6">Globusim will be always be open and honest about the way in which we manage personal information.</p>
    <h3 class="font-medium text-orange text-base">Access and Correction</h3>
    <p class="text-[15px] pb-6">Wherever possible, Globusim will let you see the information we hold about you and correct it if it is wrong. You can do so by clicking on Account details after logging onto the website with your account number and PIN.</p>
    <h3 class="font-medium text-orange text-base">Anonymity</h3>
    <p class="text-[15px] pb-6">Globusim is committed to allowing our customers to conduct their activities through any of the services we provide without being required to identify themselves. In some circumstances, such as providing us with credit card details, we obviously cannot deal with you anonymously. However, unless there are good practical or legal reasons that require you to be identified, we will give you the option of operating anonymously or pseudo-anonymously.</p>
    <h3 class="font-medium text-orange text-base">Onward transfers of Personal Information</h3>
    <p class="text-[15px] pb-6">Except as provided herein, Globusim will not provide your personal information to any other person unless we ask you and you agree, or unless we are forced to do so by law.</p>
    <h3 class="font-medium text-orange text-base">Sensitive Information</h3>
    <p class="text-[15px] pb-6">Globusim will not collect or use information about your racial or ethnic origin, political views, religious or philosophical beliefs, membership, details about your health or sex life, or any other information considered sensitive.</p>
    <h3 class="font-medium text-orange text-base">Non-personal Information</h3>
    <p class="text-[15px] pb-6">Globusim may automatically collect non-personal information about you. This includes the type of Internet Browser and IP address you use and the site which linked you to the Globusim website. This information is used to assist Globusim in providing you with an effective website. Globusim uses non-personally identifiable information for internal purposes and to help us interact with visitors to the website. We may also share non-personally identifiable information in the aggregate form with third parties to illustrate trends and patterns. You cannot be personally identified from this information.</p>
    <h3 class="font-medium text-orange text-base">Sharing of Information</h3>
    <p class="text-[15px] pb-6">Globusim may share aggregated demographic information with our partners. This information is not linked to any personal information that can identify any individual person. At times Globusim may partner with another party or use a third-party contractor to provide specific services on its behalf, such as authorization of credit card transactions and delivery of orders, and may share your information with these contractors for those limited purposes. When you use these services you will provide your name and other contact information that is necessary for the third party to provide these services. These parties are not allowed to use personally identifiable information except for the purpose of providing these services.</p>
    <p class="text-[15px] pb-6">In order to improve our customers’ product satisfaction we may use 3rd party review/survey management providers for surveys. In this case, your email address may be shared with that provider with the purpose of sending you a survey about your experience with Globusim products and services.</p>
    <h3 class="font-medium text-orange text-base">Cookies</h3>
    <p class="text-[15px] pb-6">A cookie is a piece of data temporarily stored on the user's hard drive containing information about the user. Usage of a cookie is in no way linked to any personally identifiable information while on our site. The cookie helps the user to save time. For instance, by setting a cookie on our site, you do not have to log in using your account number and PIN more than once during each visit, thereby saving time while on our site.</p>
    <h3 class="font-medium text-orange text-base">Links</h3>
    <p class="text-[15px] pb-6">This web site may contain links to other sites. Please be aware that Globusim is not responsible for the privacy practices of such other sites. This privacy statement applies solely to information collected by this web site.</p>
    
    <h2 class="font-medium text-xl text-center">Communications from the website</h2>
    <h3 class="font-medium text-orange text-base">Transactional Emails</h3>
    <p class="text-[15px] pb-6">Confirmations following any transactional activity on the Website including product and/or service purchases, refunds, email address verification, account password, etc.</p>
    <h3 class="font-medium text-orange text-base">Newsletter and Special Offers</h3>
    <p class="text-[15px] pb-6">Company news, service updates and special offers are sent out to those who opted in to receive our newsletter. If you no longer wish to receive these emails, you can easily unsubscribe by clicking the unsubscribe link which is included in every promotional email.</p>
    <h3 class="font-medium text-orange text-base">Important Service Announcements</h3>
    <p class="text-[15px] pb-6">On rare occasions we send out important information regarding changes to products and services sold on the website. These emails are non-promotional in nature and do not contain the unsubscribe link. To stop receiving these mandatory emails you need to cancel your account with Globusim.</p>
    <h3 class="font-medium text-orange text-base">Customer Service</h3>
    <p class="text-[15px] pb-6">On a regular basis in order to provide support services we may respond to customer requests by phone, email or other ways specified by the client.</p>
    <h3 class="font-medium text-orange text-base">Changes to the Privacy Policy</h3>
    <p class="text-[15px] pb-6">Globusim reserves the right to change the terms of this Privacy Policy. Any changes will be posted on this page to encourage you to check this page regularly. Your continued use of the website or the services following any changes to this Privacy Policy will constitute your acceptance of such changes.</p>
  </section>
</main>