import { CommonModule } from '@angular/common';
import { Component, NgZone } from '@angular/core';

@Component({
  selector: 'app-image-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-carousel.component.html',
  styleUrl: './image-carousel.component.scss',
})
export class ImageCarouselComponent {
  images: string[] = [
    'assets/images/icons/dialogue/1.svg',
    'assets/images/icons/dialogue/2.svg',
    'assets/images/icons/dialogue/3.svg',
    'assets/images/icons/dialogue/4.svg',
    'assets/images/icons/dialogue/5.svg',
    'assets/images/icons/dialogue/6.svg',
    'assets/images/icons/dialogue/7.svg',
    // 'assets/images/icons/dialogue/8.svg',
  ];
  currentIndex: number = 0;
  private intervalId: any;

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {
  }
  
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.startCarousel();
    
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  startCarousel(): void {
    let me = this;
    console.log("start", me)
    this.ngZone.runOutsideAngular(() => {
      me.intervalId = setInterval(() => {
        console.log("start timer")
        me.currentIndex = (me.currentIndex + 1) % me.images.length;
      }, 2000); // Cambia cada 3 segundos
    });
  }
}
