import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-btn-back',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './btn-back.component.html',
  styleUrl: './btn-back.component.scss'
})
export class BtnBackComponent {

  constructor(private location: Location) {}

  goBack(): void {
    this.location.back(); // Navegar a la página anterior
  }
}
