<main class="">
  <section class="bg-no-repeat bg-cover py-10 flex flex-col items-center"
    [style.background-image]="'url(' + (vNumberCountryName | appSliders) + ')'">
    <div class="relative flex">
      <img [src]="packageCountryName | appFlags" alt="country flag" class="absolute -left-9 top-0 w-12">
      <img [src]="vNumberCountryName | appFlags" alt="country flag" class="w-12">
    </div>
    <h1 class="flex flex-col items-center text-white text-center leading-none pt-4">
      <span transloco="choose-number.title"></span>
      <span [transloco]="'markets-vn.' + vNumberCountryName"></span>
    </h1>
  </section>

  <section class="px-6 smd:px-10">
    <p class="text-grey text-center pt-16 smd:w-4/6 smd:mx-auto" transloco="choose-number.subtitle"></p>

    <h2 class="text-center py-16 font-semibold" transloco="choose-number.top-pick-plan.title"></h2>

    <div class="flex justify-center flex-wrap gap-5">
      <article class="flex flex-col items-center rounded-3xl shadow-full p-6 text-grey w-[320px]">
        <img src="assets/images/icons/plans/1vnumber.svg" alt="" class="w-8 py-2">
        <p class="py-4 text-center" [innerHTML]="'choose-number.top-pick-plan.card-1-text' | transloco"></p>
      </article>
      <article class="flex flex-col items-center rounded-3xl shadow-full p-6 text-grey w-[320px]">
        <img src="assets/images/icons/plans/2vnumber.svg" alt="" class="w-8 py-2">
        <p class="py-4 text-center" [innerHTML]="'choose-number.top-pick-plan.card-2.' + vNumberCountryName + '-' + packageID | transloco"></p>
      </article>
      <article class="flex flex-col items-center rounded-3xl shadow-full p-6 text-grey w-[320px]">
        <img src="assets/images/icons/plans/3vnumber.svg" alt="" class="w-8 py-2">
        <p class="py-4 text-center" [innerHTML]="'choose-number.top-pick-plan.card-3-text' | transloco"></p>
      </article>
      <article class="flex flex-col items-center rounded-3xl shadow-full p-6 text-grey w-[320px]">
        <img src="assets/images/icons/plans/4vnumber.svg" alt="" class="w-8 py-2">
        <p class="py-4 text-center" [innerHTML]="'choose-number.top-pick-plan.card-4-' + packageID | transloco"></p>
      </article>
    </div>
  </section>

  <section class="px-5 smd:px-10">
    <article class="flex flex-col items-center py-16">
      @if (priceInteger) {
      <div class="relative flex items-end space-x-2 w-[190px]" style="direction: ltr;">
        <!-- <div class="text-grey strikethrough text-2xl my-auto">$5<span class="text-base align-top">99</span></div> -->
        <div class="font-medium text-[4rem] leading-none">
          <span class="text-3xl align-middle">$</span>{{ priceInteger }}<span class="text-3xl align-top">{{ priceDecimal
            }}</span>
        </div>
        <div class="absolute left-[56px] top-[38px] text-grey text-sm" transloco="choose-number.price"></div>
      </div>
      } @else {
      <app-spinner-small></app-spinner-small>
      }
    </article>
  </section>

  <section class="pb-16 px-5 smd:px-10 flex justify-center">
    <article
      class="relative flex flex-col items-center mlg:flex-row justify-center w-full max-w-[1360px] rounded-3xl shadow-full py-12 px-4 sm:px-8 mlg:ltr:mr-16 mlg:rtl:ml-16">
      <div class="flex flex-col sm:w-[150px] text-3xl font-bold pb-12 mb-auto leading-none"
        [innerHTML]="'choose-number.choose-number' | transloco"></div>
      <div
        class="flex flex-col items-center md:flex-row justify-evenly w-full mlg:w-[790px] gap-9 overflow-x-auto scrollbar-sec">
        @for (item of virtualNumbers; track $index) {
        <ul class="text-grey flex flex-col gap-1 mb-auto pb-4">
          @if (item.state !== 'Israel' || (item.state === 'Israel' && isFirstIsrael($index))) {
            <li class="font-semibold">{{ item.state }}</li>
          }@else {
            <li class="invisible">
              {{ item.state }}
            </li>
          }
          @for (did of item.dids; track $index) {
          <li class="flex gap-2 items-center">
            <span class="flex flex-1 text-sm">+{{ did.did }}</span>
            <!-- <span class="text-sm pl-6 px-2">${{ did.price }}</span> -->
            <span (click)="onChooseVNumber(did)" class="text-orange cursor-pointer uppercase"
              transloco="choose-number.buy">
            </span>
          </li>
          }
        </ul>
        }
      </div>

      <img src="assets/images/icons/plans/7vnumber.svg" alt=""
        class="w-40 mlg:absolute mlg:ltr:-right-16 mlg:rtl:-left-16 rtl:-scale-x-100 mlg:top-0 pt-16 mlg:pt-8">
    </article>
  </section>

  <section class="py-16 px-5">
    <h2 class="text-center pb-16 font-semibold" transloco="choose-number.need-more-info.title"></h2>
    <app-need-more-info-form [packageId]="packageID" [packageIso]="packageCountryIso"></app-need-more-info-form>
  </section>
</main>