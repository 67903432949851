import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-info-pop-up',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './info-pop-up.component.html',
  styleUrl: './info-pop-up.component.scss'
})
export class InfoPopUpComponent {
  @Input() showPopUp: boolean = false;
  @Input() translate: string = ""

  @Output() closePopUpEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(private router: Router) {}
  
  closePopUp() {
    this.showPopUp = false;
    this.closePopUpEvent.emit();
  }
  
  ngOnInit(): void {}
}
