<section class="rounded-xl shadow-full border border-greyLight py-9 px-6 md:px-16 w-full">
  <h2 class="text-2xl font-bold" transloco="profile-vn.my-vnumbers.my-cli.title"></h2>
  <span class="text-sm" transloco="profile-vn.my-vnumbers.my-cli.subtitle"></span>

  <form [formGroup]="editCLIForm" (ngSubmit)="updateCallerId()"
    class="grid grid-cols-2 grid-rows-1 gap-1 sm:gap-4 py-12 text-sm">
    <span class="font-semibold text-sm" transloco="profile-vn.my-vnumbers.my-cli.phone-numbers"></span>
    <span class="font-semibold text-sm" transloco="form.name"></span>
    @for (num of numbers; track $index) {

    @if (deleteConfirmationId === num.id) { <!-- SHOW DELETE CONFIRMATION -->
    <div class="flex items-center">
      <span class="text-center font-medium text-red"
        transloco="profile-vn.my-vnumbers.my-cli.sure-to-delete-number"></span>
    </div>
    <div class="flex w-full gap-1 sm:gap-3">
      <button type="button" class="flex justify-center border hover:border-grey py-1 px-4 rounded-xl w-full"
        (click)="deleteConfirmationId = null">
        <span transloco="btn-vn.no"></span>
      </button>
      <button type="button"
        class="flex justify-center gap-2 border hover:border-red hover:text-red py-1 px-4 rounded-xl w-full"
        (click)="deleteCallerId(num.id)">
        <span transloco="btn-vn.yes"></span>
        <img src="assets/images/icons/profile/remove.svg" alt="remove icon" class="w-5">
      </button>
    </div>

    }@else if (editingId === num.id) { <!-- SHOW EDIT CLI -->
    <!-- EDIT PHONE -->
    <div class="flex flex-col items-center gap-1 sm:gap-3">
      <!-- PREFIX AND PHONE INPUT -->
      <div class="flex gap-1">
        <input #prefixInput type="text" [placeholder]="'form.prefix' | transloco" formControlName="prefix" (keypress)="onKeyPress($event)" (input)="onInputChange($event, 'prefix')"
          class="{{ editCLIForm.get('prefix').invalid && formSubmitted ? 'border-red' : ''}} flex items-center gap-3 py-2 px-4 border bg-greyLight rounded w-1/4" />
        <input type="text" [placeholder]="'form.phone' | transloco" formControlName="phone" (keypress)="onKeyPress($event)" (input)="onInputChange($event, 'phone')"
          class="{{ editCLIForm.get('phone').invalid && formSubmitted ? 'border-red' : ''}} flex flex-1 items-center gap-3 py-2 px-4 border bg-greyLight rounded w-3/4" />
      </div>
      <!-- CONFIRM PREFIX AND PHONE INPUT -->
       @if(editCLIForm.get('prefix').dirty || editCLIForm.get('phone').dirty) {

         <div class="relative flex gap-1">
           <input #prefixInput type="text" [placeholder]="'form.confirm-prefix' | transloco" formControlName="confirmPrefix" (keypress)="onKeyPress($event)" (input)="onInputChange($event, 'confirmPrefix')"
           class="{{ editCLIForm.get('confirmPrefix').invalid && formSubmitted ? 'border-red' : ''}} flex items-center gap-3 py-2 px-4 border bg-greyLight rounded w-1/4" />
           <input type="text" [placeholder]="'form.confirm-phone' | transloco" formControlName="confirmPhone" (keypress)="onKeyPress($event)" (input)="onInputChange($event, 'confirmPhone')"
           class="{{ editCLIForm.get('confirmPhone').invalid && formSubmitted ? 'border-red' : ''}} flex flex-1 items-center gap-3 py-2 px-4 border bg-greyLight rounded w-3/4" />
           @if (editCLIForm.errors?.['phoneMismatch'] && editCLIForm.get('confirmPhone')?.touched) {
             <small class="text-red absolute top-10 leading-none" transloco="form.phone-not-match"></small>
            }
          </div>
        }
    </div>
    <!-- EDIT NAME AND BUTTONS -->
    <div class="flex flex-col md:flex-row md:items-center gap-1 sm:gap-3 md:min-w-48 mb-auto">
      <!-- NAME INPUT -->
      <input #nameInput [placeholder]="'form.name' | transloco" formControlName="name"
        class="{{ editCLIForm.get('name').invalid && formSubmitted ? 'border-red' : ''}} flex flex-1 items-center gap-3 py-2 px-4 border bg-greyLight rounded" />
      <div class="flex gap-3">
        <!-- SAVE BUTTON -->
        <button type="submit" transloco="btn-vn.save" class="flex text-green text-xs hover:scale-105"></button>
        <!-- CANCEL BUTTON -->
        <button (click)="stopEditing()" transloco="btn-vn.cancel"
          class="flex text-red text-xs hover:scale-105"></button>
      </div>
    </div>
    }@else { <!-- SHOW CLI AND EDIT BUTTON -->
    <div class="flex items-center gap-1 sm:gap-3 py-2 px-2 border rounded md:min-w-48">
      <img [src]="num.countryName | appFlags" class="w-5">
      <span class="text-sm font-semibold whitespace-nowrap overflow-hidden text-ellipsis ">{{ num.callerId | phoneFormat:num.mask:num.prefix }}</span>
    </div>
    <div class="flex items-center gap-2 sm:gap-4">
      <!-- NAME -->
      <div class="flex flex-1 items-center gap-3 py-2 px-4 border rounded min-w-12 md:min-w-48">
        <span class="text-sm whitespace-nowrap overflow-hidden text-ellipsis " >{{ num.comment }}</span>
      </div>
      <!-- EDIT BUTTON -->
      <button (click)="startEditing(num.id)" class="flex gap-1 text-orange text-xs hover:scale-105 popover-father">
        <!-- <span class="hidden sm:block" transloco="btn-vn.edit"></span> -->
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="orange"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-edit-2">
          <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
        </svg>
        <div
          class="popover hidden absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 shadow-lg">
          <span transloco="btn-vn.edit"></span>
        </div>
      </button>
      <!-- REMOVE BUTTON -->
      @if (canDeletePhone()) {
      <button (click)="confirmDelete(num.id)" class="flex gap-1 text-orange text-xs hover:scale-105 popover-father">
        <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
            stroke="orange" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div
          class="popover hidden absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 shadow-lg">
          <span transloco="btn-vn.remove"></span>
        </div>
      </button>
      }
    </div>
    }
    }
  </form>

  @if (canAddMorePhones()) {
  <!-- ADD A NEW PHONE -->
  @if (isAddNewPhone) {
  <h4 class="text-center font-medium" transloco="profile-vn.my-vnumbers.my-cli.add-new-number"></h4>
  <form [formGroup]="newCLIForm" class="flex flex-col md:flex-row items-center gap-2 sm:gap-4 w-full text-sm"
    (ngSubmit)="onSubmitNewPhone()">
    <!-- PHONE INPUT -->
    <div class="flex flex-col items-center flex-1 gap-1 w-full md:w-1/2">
      <div class="flex gap-1">
        <input #prefix1Input type="text" [placeholder]="'form.prefix' | transloco" formControlName="newPrefix"  (keypress)="onKeyPress($event)" (input)="onInputChange($event, 'newPrefix')"
          class="{{ newCLIForm.get('newPrefix').invalid && formSubmitted ? 'border-red' : ''}} flex items-center gap-3 py-2 px-4 border rounded w-1/4" />
        <input type="text" [placeholder]="'form.phone' | transloco" formControlName="newPhone" (keypress)="onKeyPress($event)" (input)="onInputChange($event, 'newPhone')"
          class="{{ newCLIForm.get('newPhone').invalid && formSubmitted ? 'border-red' : ''}} flex flex-1 items-center gap-3 py-2 px-4 border rounded w-2/4" />
      </div>
      <div class="relative flex gap-1">
        <input #prefix1Input type="text" [placeholder]="'form.confirm-prefix' | transloco"
          formControlName="confirmNewPrefix"  (keypress)="onKeyPress($event)" (input)="onInputChange($event, 'confirmNewPrefix')"
          class="{{ newCLIForm.get('confirmNewPrefix').invalid && formSubmitted ? 'border-red' : ''}} flex items-center gap-3 py-2 px-4 border rounded w-1/4" />
        <input type="text" [placeholder]="'form.confirm-phone' | transloco" formControlName="confirmNewPhone"  (keypress)="onKeyPress($event)" (input)="onInputChange($event, 'confirmNewPhone')"
          class="{{ newCLIForm.get('confirmNewPhone').invalid && formSubmitted ? 'border-red' : ''}} flex flex-1 items-center gap-3 py-2 px-4 border rounded w-2/4" />
        @if (newCLIForm.errors?.['phoneMismatch'] && newCLIForm.get('confirmNewPhone')?.touched) {
        <small class="text-red absolute top-10" transloco="form.phone-not-match"></small>
        }
      </div>
    </div>
    <!-- ADD NAME AND BUTTONS -->
    <div class="flex flex-col sm:flex-row sm:items-center flex-1 w-full md:w-1/2 gap-2 sm:gap-4 mb-auto">
      <!-- NAME INPUT -->
      <input type="text" [placeholder]="'form.name' | transloco" formControlName="newName"
        class="{{ newCLIForm.get('newName').invalid && formSubmitted ? 'border-red' : 'border'}} flex flex-1 items-center gap-3 py-2 px-4 border rounded md:min-w-48" />
      <!-- SAVE BUTTON -->
      <button type="submit" class="flex text-green text-xs hover:scale-105" transloco="btn-vn.save"></button>
      <!-- CANCEL BUTTON -->
      <button (click)="cancelAddNewPhone()" transloco="btn-vn.cancel"
        class="flex text-red text-xs hover:scale-105"></button>
    </div>
  </form>
  }@else {
  <!-- ADD BUTTON -->
  <button class="flex items-center text-orange hover:scale-105 font-semibold" (click)="addNewPhone()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor"
      stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus mr-1">
      <line x1="12" y1="5" x2="12" y2="19" />
      <line x1="5" y1="12" x2="19" y2="12" />
    </svg>
    <span transloco="btn-vn.add-new"></span>
  </button>
  }
  }@else {
  <div>
    <span transloco="profile-vn.my-vnumbers.my-cli.reached-max-callersid"></span>
  </div>
  }
</section>