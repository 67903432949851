import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '@app/services/auth/auth.service';
import { ApiMessageCode } from '@app/services/models/base/ApiMessageCode';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { PopUpService } from '../../services/pop-up/pop-up.service';
import { LoadingService } from '@app/services/loading/loading.service';

@Component({
  selector: 'app-password-forgot-page',
  standalone: true,
  imports: [ReactiveFormsModule, TranslocoModule],
  templateUrl: './password-forgot-page.component.html',
  styleUrl: './password-forgot-page.component.scss'
})
export class PasswordForgotPageComponent {

  authService = inject(AuthService);
  loadingService = inject(LoadingService);
  popUpService = inject(PopUpService);
  translateService = inject(TranslocoService);

  formSubmitted: boolean = false;
  currentLang: string = "";

  emailForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  })

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    // Subscribe to language changes
    this.translateService.langChanges$.subscribe((lang: string) => {
      this.currentLang = lang; // Update currentLang when language changes
    });

    // Initialize currentLang with the current active language
    this.currentLang = this.translateService.getActiveLang();
  }
  
  async onSubmit() {
    this.formSubmitted = true;

    if (this.emailForm.valid) {
      this.formSubmitted = false;
      
      const jsonData = {
        email: this.emailForm.get('email').value,
        languageIso: this.currentLang
      }

      try {
        const res = await this.authService.resetPassword(jsonData);
        
        if (res.isSuccessful && res.result) {
          this.popUpService.openPopUp('verifying-password');
        }
      } catch (error) {
        console.error(error)
        const apiMessageCode = ApiMessageCode.MessageCode
        const message = error.error.messages[0];
        if (message.code === apiMessageCode.emailNotExist) {
          this.popUpService.openPopUp('email-not-exist');
        }
      }
    }
  }
}
