<main class="px-5">
  <section class="flex justify-center">
    <div class="flex flex-col w-fit py-16">
      <img src="assets/images/icons/plans/5vnumber.svg" alt="" class="w-20 ml-auto">
      <h2 class="text-center px-12 py-4 font-semibold" transloco="thank-you-vn.title"></h2>
      <img src="assets/images/icons/FAQ_1vnumber.svg" alt="" class="w-40 ">
    </div>
  </section>

  <section class="flex flex-col items-center">
    <article class="flex flex-col-reverse items-center smd:flex-row gap-10 max-w-[680px]">
      <img src="assets/images/icons/order/hands.svg" alt="" class="w-80">
      <div class="flex flex-col items-center smd:items-start">
        <h3 transloco="thank-you-vn.whats-next"></h3>
        <p class="py-8" transloco="thank-you-vn.whats-next-desc"></p>
        <div class="text-lg font-medium">
          <span class="text-orange">Welcome to VNumber,</span><br>
          <span class="">a company by 011Global</span>
        </div>
      </div>
    </article>

    <article class="py-16">
      <button routerLink="/" class="px-16 py-4 bg-orange w-full text-white uppercase rounded-full font-semibold" transloco="btn-vn.return"></button>
    </article>
  </section>
</main>