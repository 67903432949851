import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideTransloco } from '@jsverse/transloco';
import { TranslocoHttpLoader } from './transloco-loader';
import { SettingsService } from './services/settings.service';
import {
  cookiesStorage,
  provideTranslocoPersistLang,
} from '@jsverse/transloco-persist-lang';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { AuthInterceptor } from './helpers/interceptors/auth.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideTransloco({
      loader: TranslocoHttpLoader,
      config: {
        availableLangs: [
          { id: 'en', label: 'English' },
          { id: 'es', label: 'Español' },
          { id: 'he', label: 'עברית' },
          { id: 'pt', label: 'Português' },
        ],
        reRenderOnLangChange: true,
        fallbackLang: 'en',
        defaultLang: 'en',
        missingHandler: {
          useFallbackTranslation: true,
          allowEmpty: !isDevMode(),
          logMissingKey: true,
        },
        // interpolation: ['<<<', '>>>']
      },
    }),
    provideTranslocoMessageformat(),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        es: 'es-ES',
        he: 'he-IL',
        pt: 'pt-BR',
      },
    }),
    provideTranslocoPersistLang({
      storage: {
        // useValue: cookiesStorage(),
        useValue: localStorage,
      },
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    SettingsService,
  ],
};
