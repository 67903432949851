<div class="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
  <div class="flex justify-center flex-1 items-center gap-3">
    @if (isLoading) {
      <app-spinner-small></app-spinner-small>
    }@else {
      <button
      (click)="handlePrevious()"
      [disabled]="pageIndex === 1"
      transloco="btn-vn.previous"
      class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
      ></button>
      <button
      (click)="handleNext()"
      [disabled]="!hasMoreRecords"
      transloco="btn-vn.next"
      class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
      ></button>
    }
    </div>
  </div>