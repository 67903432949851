  <!-- BILLING FORM -->
  <article class="py-4">
    
    <form [formGroup]="billingForm" (ngSubmit)="onSubmitBilling()">
      <!--Address input-->
      <div class="mb-4">
        <label for="address" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.address"></label>
        <input type="text" formControlName="address" [placeholder]="'form.address' | transloco"
          class="{{billingForm.get('address')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
          id="address" />
      </div>
      
      <!-- City & zip inputs -->
      <div class="flex gap-8">
        <!-- CITY FIELD -->
        <div class="mb-4 w-1/2">
          <label for="city" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.city"></label>
          <input type="text" formControlName="city" [placeholder]="'form.city' | transloco"
          class="{{billingForm.get('city')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
          id="city" />
        </div>
        <!-- ZIP CODE FIELD -->
        <div class="mb-4 w-1/2">
          <label for="zipCode" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.zip-code"></label>
          <input type="text" formControlName="zipCode" [placeholder]="'form.zip-code' | transloco"
          class="{{billingForm.get('zipCode')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
          id="zipCode" />
        </div>
      </div>

      <!-- Country & State inputs -->
      <div class="flex gap-8">
        <!-- COUNTRY FIELD -->
        <div class="mb-4 w-1/2">
          <label for="country" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.country"></label>
          <select type="text" formControlName="country" (change)="onCountryChange($event)"
            class="{{billingForm.get('country')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
            id="country">
            @for (country of countries; track $index) {
              <option class="" value={{country.countryID}}>{{country.countryName}}</option>
            }
          </select>
        </div>
        <!-- STATE FIELD -->
        <div class="mb-4 w-1/2">
          <label for="state" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.state"></label>
          <select type="text" formControlName="state"
            class="{{billingForm.get('state')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
            id="state">
              @for (state of states; track $index) {
                <option class="" value={{state.stateID}}>{{state.state}}</option>
              }
          </select>

        </div>
      </div>
    </form>
  </article>