import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopUpService {
  private _showPopUp = signal(false);
  private _popUpMessage = signal('');

  showPopUp = this._showPopUp.asReadonly();
  popUpMessage = this._popUpMessage.asReadonly();

  openPopUp(message: string) {
    this._popUpMessage.set(message);
    this._showPopUp.set(true);
  }

  closePopUp() {
    this._showPopUp.set(false);
  }
}
