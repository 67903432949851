import { Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlagsPipe } from '@app/pipes/flags.pipe';
import { PhoneFormatPipe } from '@app/pipes/phone-format.pipe';
import { IForwardingNumber, IMyVNumbers } from '@app/services/models/my-vnumbers.model';
import { MyVnumbersService } from '@app/services/my-vnumbers/my-vnumbers.service';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-vnumber-details',
  standalone: true,
  imports: [
    FlagsPipe,
    FormsModule,
    PhoneFormatPipe,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule,
  ],
  templateUrl: './forwarding-settings.component.html',
  styleUrl: './forwarding-settings.component.scss',
})
export class ForwardingSettingsComponent {
  myVnumberService = inject(MyVnumbersService);

  @Input() did: IMyVNumbers;
  @Output() updateForwarding: EventEmitter<IMyVNumbers> = new EventEmitter<IMyVNumbers>();
  @ViewChild('prefixInput') prefixInput!: ElementRef;
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  @ViewChild('inputEmail') inputEmail!: ElementRef;
  
  number: IForwardingNumber[];

  @Input() smsEmail: string;
  @Input() didid: number;

  isEditCallForwarding: boolean = false;
  isEditSMSForwarding: boolean = false;
  formSubmitted: boolean = false;

  smsForwardingForm = this.fb.group({
    email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
    confirmEmail: ['', [Validators.required, Validators.email]],
  }, { validators: this.emailsMatchValidator() })

  callForwardingForm = this.fb.group({
    prefix: ['', [Validators.required, Validators.maxLength(3), Validators.pattern('^[0-9]*$')]],
    phone: ['', [Validators.required, Validators.maxLength(15), Validators.pattern('^[0-9]*$')]],
    confirmPrefix: ['', [Validators.required, Validators.maxLength(3), Validators.pattern('^[0-9]*$')]],
    confirmPhone: ['', [Validators.required, Validators.maxLength(15), Validators.pattern('^[0-9]*$')]]
  }, { validators: this.phoneMatchValidator() })
  
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.number = this.did.forwardingNumbers;
    this.smsForwardingForm.patchValue({
      email: this.smsEmail
    })
  }

  emailsMatchValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const newEmail = form.get('email')?.value;
      const confirmEmail = form.get('confirmEmail')?.value;
      return newEmail === confirmEmail ? null : { emailsMismatch: true };
    };
  }

  phoneMatchValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const newPhone = form.get('prefix')?.value + form.get('phone')?.value;
      const confirmPhone = form.get('confirmPrefix')?.value + form.get('confirmPhone')?.value;;
      return newPhone === confirmPhone ? null : { phoneMismatch: true };
    };
  }

  onInputChange(event: Event, controlName: string) {
    const input = event.target as HTMLInputElement;
    const sanitizedValue = input.value.replace(/[^0-9]/g, '');
    this.callForwardingForm.get(controlName).setValue(sanitizedValue, {emitEvent: false});
  }

  onKeyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  editCallForwarding(id: number = 0) {
    setTimeout(() => {
      if (this.prefixInput) this.prefixInput.nativeElement.focus();
    }, 0);
  
    let selectedNumber = this.number.find(i => i.forwardingNumberID === id);
  
    if (selectedNumber) {
      this.callForwardingForm.patchValue({
        prefix: selectedNumber.prefix,
        phone: selectedNumber.phoneNumber.replace(selectedNumber.prefix, ''),
        confirmPrefix: '',
        confirmPhone: ''
      });
    }
  
    this.isEditCallForwarding = true;
  }

  editSMSEmail() {
    setTimeout(() => {
      if (this.inputEmail) this.inputEmail.nativeElement.focus(); // Focusing the input
    }, 0);

    this.smsForwardingForm.patchValue({
      confirmEmail: ''
    })

    this.isEditSMSForwarding = true
    this.smsForwardingForm.get('email').enable();
  };



  cancelEditSMSmail() {
    this.isEditSMSForwarding = false;
    this.smsForwardingForm.get('email').disable()
  }

  cancelEditCallNumber() {
    this.isEditCallForwarding = false;
  }

  saveChangesCallForwarding() {
    this.formSubmitted = true
    if (this.callForwardingForm.valid) {
      
      const { prefix, phone } = this.callForwardingForm.value
      
      const jsonData = {
        didid: this.did.didid,
        forwardingNumber: prefix + phone,
      }
      
      this.updateForwardinData(jsonData)
      this.formSubmitted = false;
    }
  }

  saveChangesSMSForwarding() {
    this.formSubmitted = true

    if (this.smsForwardingForm.valid) {
      
      const { email } = this.smsForwardingForm.value
      
      const jsonData = {
        didid: this.did.didid,
        email: email
      }
      
      this.updateForwardinData(jsonData)
      this.formSubmitted = false;
    }
  }

  // ngAfterViewInit() {
  //   if (this.phoneInput) {
      
  //     const inputElement = this.phoneInput.nativeElement;
  //     if (inputElement) {
  //       const iti = intlTelInput(inputElement, {
  //         initialCountry: 'US',
  //         separateDialCode: true,
  //         formatOnDisplay: true,
  //         strictMode: true,
  //         loadUtilsOnInit:
  //         'https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/js/utils.js',
  //       });
        
  //       inputElement.addEventListener('input', () => {
  //         const phoneNumber = iti.getNumber();
  //         this.callForwardingForm.get('phone').setValue(phoneNumber);
  //         // const dialCode = iti.getSelectedCountryData().dialCode;
  //         // const nationalNumber = phoneNumber.replace(`+${dialCode}`, '').trim();
          
  //         // this.selectedCountryName = iti.getSelectedCountryData().name;
  //         // this.personalInfoForm.get('phone').setValue(nationalNumber);
  //         // this.personalInfoForm.get('dialCode').setValue(dialCode);
  //         // this.personalInfoForm.get('phone').updateValueAndValidity();
  //       });
  //     }
  //   }
  // }

  async updateForwardinData(data) {

    try {
      const res = await this.myVnumberService.updateForwardingNumber(data);

      if (res.isSuccessful && res.result) {
        // this.updateForwarding.emit(this.did);
        this.getMyVNumbers()
        this.cancelEditCallNumber()
        this.cancelEditSMSmail()
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getMyVNumbers() {
    try {
      const res = await this.myVnumberService.getMyVNumbers<IMyVNumbers[]>()

      if (res.isSuccessful) {
        let result = res.result.find(i=>i.didid === this.didid)
        this.number = result.forwardingNumbers ?? result.forwardingNumbers
        this.smsEmail = result.smsEmail ?? result.smsEmail;
      }
    } catch (error) {
      console.error(error)
    }
  }
}
