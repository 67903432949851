import { Component, inject } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { CreditCardComponent } from '@app/core/shared/profile/credit-card/credit-card.component';
import { AboutPageComponent } from '../../../../pages/about-page/about-page.component';
import { AddCreditService } from '@app/services/add-credit/add-credit.service';
import { AuthService } from '@app/services/auth/auth.service';
import { PaymentMethodsService } from '@app/services/payment-methods/payment-methods.service';
import { RecaptchaService } from '@app/services/recatpcha/recaptcha.service';
import { AccountInfoService } from '../../../../services/account-info/account-info.service';
import { PopUpService } from '@app/services/pop-up/pop-up.service';
import { LoadingService } from '@app/services/loading/loading.service';
import { FormGroup, FormsModule } from '@angular/forms';
import { PaymentMethodsComponent } from '../billing-view/payment-methods/payment-methods.component';
import { ICreditCardInfo } from '@app/services/models/credit-card-info.model';

@Component({
  selector: 'app-add-credit',
  standalone: true,
  imports: [
    AboutPageComponent,
    CreditCardComponent,
    FormsModule,
    PaymentMethodsComponent,
    TranslocoModule,
  ],
  templateUrl: './add-credit.component.html',
  styleUrl: './add-credit.component.scss',
})
export class AddCreditComponent {

  accountInfoService = inject(AccountInfoService);
  addCreditService = inject(AddCreditService);
  authService = inject(AuthService);
  loadingService = inject(LoadingService);
  paymentMethodsService = inject(PaymentMethodsService);
  popUpService = inject(PopUpService);
  recaptchaService = inject(RecaptchaService);

  paymentID: number = 0;
  customAmount: string = '';

  confirmationID: number = 0;

  showConfirmationPopUp: boolean = false;
  showCreditCard: boolean = false;
  isNewMethod: boolean = false;
  termsAndConditions: boolean = true;
  isPrimary: boolean = false;
  keepCard: boolean = true;

  savedCards: ICreditCardInfo[] = [];

  creditCardForm = this.paymentMethodsService.paymentMethodsForm.get(
    'creditCardFrom'
  ) as FormGroup;
  addressCardForm = this.paymentMethodsService.paymentMethodsForm.get(
    'addressCardForm'
  ) as FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.getAllSavedCards();
    // this.creditCardForm.patchValue({
    //   cardNumber: '4242424242424242',
    //   cardHolder: 'sfdfsdf sdfsdfds',
    //   cardExpirationMM: '09',
    //   cardExpirationYY: '2026',
    //   cardCVV: '234',
    // });

    // this.addressCardForm.patchValue({
    //   city: 'Montevideo',
    //   state: '4510',
    //   zipCode: '11200',
    //   address: '2119 Colonia',
    //   country: '217',
    // })
  }

  selectAmount(amount: string): void {
    this.customAmount = amount;
  }

  onSelectedPaymentMethod(paymentId: number) {
    this.paymentID = paymentId;
  }

  onCloseConfirmationPopUp() {
    this.showConfirmationPopUp = false;
    this.accountInfoService.onCreditAdded(true);
    this.customAmount = '';
  }

  numberOnly(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/[^0-9]/g, '');
    if (value.length > 2) {
      value = value.slice(0, 2);
    }

    const numValue = parseInt(value, 10);
    if (numValue > 99) {
      value = '99';
    } else if (numValue === 0 && value !== '') {
      value = '1';
    }

    this.customAmount = value;
    input.value = this.customAmount;
  }

  shouldDisableButton(): boolean {
    if (this.customAmount === '') return true;

    if (!this.termsAndConditions) return true;

    if (this.isNewMethod) {
      return this.creditCardForm.invalid || this.addressCardForm.invalid;
    } else {
      return this.paymentID === 0;
    }
  }

  showCreditCardForm(): void {
    this.showCreditCard = true;
  }

  onAddCreditFromNewCard() {
    this.isNewMethod = true;
    this.addCredit();
  }

  async getAllSavedCards() {
    try {
      const res = await this.paymentMethodsService.getAllSavedCreditCards<
        ICreditCardInfo[]
      >();

      if (res.isSuccessful && res.result) {
        this.savedCards = res.result;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async addPaymentMethod() {
    const {
      cardNumber,
      cardHolder,
      cardExpirationMM,
      cardExpirationYY,
      cardCVV,
      isPrimaryCard,
    } = this.creditCardForm.value;

    const { city, state, zipCode, address, country } =
    this.addressCardForm.value;

    const token = await this.recaptchaService.executeRecaptcha('addCreditCard')
    
    const jsonData = {
      CreditCardNumber: cardNumber,
      CardHolder: cardHolder,
      ExpirationMonth: Number(cardExpirationMM),
      ExpirationYear: Number(cardExpirationYY),
      SecurityCode: cardCVV,
      IsPrimary: isPrimaryCard ? 1 : 0,
      City: city,
      State: state,
      Zip: zipCode,
      Address: address,
      CountryNumber: country.toString(),
      IsSaveCard: 1, // always true in this scenario
      recaptcha: token
    };

    // const jsonData = {
    //   creditCardNumber: cardNumber,
    //   cardHolder: cardHolder,
    //   expirationMonth: cardExpirationMM,
    //   expirationYear: cardExpirationYY,
    //   securityCode: cardCVV,
    //   isPrimary: isPrimaryCard,
    //   address: {
    //     countryID: country,
    //     city: city,
    //     state: state,
    //     zipCode: zipCode,
    //     address: address,
    //     stateID: state,
    //   },
    // };

    try {
      const res = await this.paymentMethodsService.createCreditCard(jsonData);

      if (res.response_code === 200) {
        console.log('tarjeta guardada')
        await this.getAllSavedCards();
      }

    } catch (error) {}
  }

  async addCredit() {
    this.loadingService.setLoading(true);
    const amountToAdd = Number(this.customAmount) || 0;
    let jsonData;
    const token = await this.recaptchaService.executeRecaptcha(
      'vNumberPurchase'
    );

    if (this.isNewMethod) {
      const {
        cardNumber,
        cardHolder,
        cardExpirationMM,
        cardExpirationYY,
        cardCVV,
        isPrimaryCard,
        keepCard,
      } = this.creditCardForm.value;

      const { city, state, zipCode, address, country } =
        this.addressCardForm.value;

      if (keepCard) this.addPaymentMethod();

      jsonData = {
        CreditCardNumber: cardNumber,
        CardHolder: cardHolder,
        ExpirationMonth: Number(cardExpirationMM),
        ExpirationYear: Number(cardExpirationYY),
        SecurityCode: cardCVV,
        IsPrimary: isPrimaryCard ? 1 : 0,
        City: city,
        State: state,
        Zip: zipCode,
        Address: address,
        CountryNumber: country,
        IsSaveCard: keepCard ? 1 : 0,
        Amount: amountToAdd,
        recaptcha: token
      };
    } else {
      jsonData = {
        PaymentId: this.paymentID,
        Amount: amountToAdd,
        recaptcha: token
      };
    }

    try {
      const res = await this.addCreditService.addCredit(jsonData);

      if (res.response_code === 200) {
        this.loadingService.setLoading(false);
        this.confirmationID = res.confirmationId;
        this.showConfirmationPopUp = true;
        this.showCreditCard = false;
        this.customAmount = ''; 
      }

      if (res.response_code === 100) {
        this.popUpService.openPopUp('server-error');
        this.loadingService.setLoading(false);
      }
    } catch (error) {
      this.loadingService.setLoading(false);
      this.popUpService.openPopUp('server-error');
      console.error(error);
    }
  }
}
