import { Component, inject } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { AccessNumbersComponent } from '../access-numbers/access-numbers.component';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { InquirysService } from '@app/services/inquirys/inquirys.service';
import { PopUpService } from '@app/services/pop-up/pop-up.service';
import { LoadingService } from '@app/services/loading/loading.service';

@Component({
  selector: 'app-support',
  standalone: true,
  imports: [
    AccessNumbersComponent,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  templateUrl: './support.component.html',
  styleUrl: './support.component.scss',
})
export class SupportComponent {
  inquiryService = inject(InquirysService);
  translateService = inject(TranslocoService);
  loadingService = inject(LoadingService);
  popUpService = inject(PopUpService);

  subjectForm = this.fb.group({
    subject: ['', Validators.required],
    message: ['', Validators.required],
  });

  formSubmitted: boolean = false;
  currentLang: string;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    // Subscribe to language changes
    this.translateService.langChanges$.subscribe((lang: string) => {
      this.currentLang = lang; // Update currentLang when language changes
    });

    // Initialize currentLang with the current active language
    this.currentLang = this.translateService.getActiveLang();
  }

  async onSubmitSubjectForm() {
    this.formSubmitted = true;

    if (this.subjectForm.valid) {
      const jsonData = {
        languageISO: this.currentLang,
        message: this.subjectForm.get('message').value,
        subject: this.subjectForm.get('subject').value,
      };

      try {
        const res = await this.inquiryService.createSupportSubject(jsonData)

        if (res.isSuccessful && res.result) {
          this.popUpService.openPopUp('contact');
          this.formSubmitted = false;
          this.subjectForm.reset();
        }
      } catch (error) {
        console.error(error)
        this.popUpService.openPopUp('server-error');
      }
    }
  }
}
