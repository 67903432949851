import {
  Component,
  ElementRef,
  Inject,
  inject,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import intlTelInput from 'intl-tel-input';
import { TranslocoModule } from '@jsverse/transloco';
import { CountriesService } from 'src/app/services/countries/countries.service';
import { RouterModule } from '@angular/router';
import { LoadingService } from '@app/services/loading/loading.service';
import { PopUpService } from '@app/services/pop-up/pop-up.service';

@Component({
  selector: 'app-register-form',
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, TranslocoModule],
  templateUrl: './register-form.component.html',
  styleUrl: './register-form.component.scss',
})
export class RegisterFormComponent {
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  countryService = inject(CountriesService);
  loadingService = inject(LoadingService);
  popUpService = inject(PopUpService);

  vNumberCountries: any;
  packageCountries: any;

  registerForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    name: ['', Validators.required],
    packageCountry: ['', Validators.required],
    vNumberCountry: ['', Validators.required],
    zipCode: ['', Validators.required],
    phone: ['', Validators.required, this.phoneNumberValidator],
    dialCode: [''],
  });

  formSubmitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.getVNCountries();
    this.getPackageCountries();
  }

  async onSubmit() {
    this.formSubmitted = true;
    this.loadingService.setLoading(true);

    if (this.registerForm.valid) {
      this.formSubmitted = false;
      this.loadingService.setLoading(false);
    }
  }

  async getVNCountries() {
    const res = await this.countryService.getDIDCountries();
    this.vNumberCountries = res.result;
  }

  async getPackageCountries() {
    const res = await this.countryService.getPackageCountries();
    this.packageCountries = res.result;
  }

  ngAfterViewInit() {
    if (this.platformId === 'browser') {
      const inputElement = this.phoneInput.nativeElement;
      if (inputElement) {
        const iti = intlTelInput(inputElement, {
          initialCountry: 'US',
          separateDialCode: true,
          formatOnDisplay: true,
          strictMode: true,
          loadUtilsOnInit:
            'https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/js/utils.js',
        });

        inputElement.addEventListener('input', () => {
          const phoneNumber = iti.getNumber();
          const dialCode = iti.getSelectedCountryData().dialCode;
          const nationalNumber = phoneNumber.replace(`+${dialCode}`, '').trim();

          // this.selectedCountryName = iti.getSelectedCountryData().name;
          this.registerForm.get('phone').setValue(nationalNumber);
          this.registerForm.get('dialCode').setValue(dialCode);
          this.registerForm.get('phone').updateValueAndValidity();
        });
      }
    }
  }

  phoneNumberValidator(
    control: FormControl
  ): Promise<{ [key: string]: boolean } | null> {
    return new Promise((resolve) => {
      const cleanedNumber = control.value;

      if (cleanedNumber) {
        const input = document.querySelector('#phoneInput') as HTMLInputElement;
        const iti = intlTelInput.getInstance(input);

        if (iti.isValidNumber()) {
          resolve(null);
        } else {
          resolve({ invalidPhoneNumber: true });
        }
      } else {
        resolve(null);
      }
    });
  }
}
