<div class="relative w-full overflow-hidden">
  <div class="flex transition-transform duration-1000 ease-in-out" [ngStyle]="{'transform': 'translateX(' + (-currentIndex * 100) + '%)'}">
    <div *ngFor="let item of items" class="min-w-full flex flex-col items-center text-center pb-16 pr-4">
      <article class="relative shadow-full rounded-3xl p-6 max-w-[380px] h-[195px]">
        <img [src]="'assets/images/icons/' + item.position + 'vnumber.svg'" alt="" class="w-16 absolute left-0 top-5">
        <h3 class="pt-8" [transloco]="'home.advantages.' + item.title"></h3>
        <p class="text-grey" [transloco]="'home.advantages.' + item.description"></p>
      </article>
    </div>
  </div>

  <!-- Pagination Dots -->
  <div class="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-5">
    <span *ngFor="let item of items; let i = index"
          class="w-4 h-4 rounded-full bg-orange cursor-pointer {{ i !== currentIndex ? 'opacity-50' : 'opacity-100'}}" 
          (click)="goToSlide(i)">
    </span>
  </div>
</div>
