import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard  {
  constructor(
    private authenticationService: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.authenticationService.IsLogin()) {
      return true;
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
 
}