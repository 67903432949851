import {
  Component,
  computed,
  inject,
  Signal,
  signal,
  ViewChild,
} from '@angular/core';
import { CouponCodeComponent } from 'src/app/core/shared/coupon-code/coupon-code.component';
import { PaymentFormComponent } from 'src/app/core/shared/payment/payment-form/payment-form.component';
import { BillingFormComponent } from 'src/app/core/shared/payment/billing-form/billing-form.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SubmitOrderService } from 'src/app/services/submit-order/submit-order.service';
import { FormGroup } from '@angular/forms';
import { CouponCodeService } from 'src/app/services/coupon-code/coupon-code.service';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { PackagePriceService } from '@app/services/package-price/package-price.service';
import { SquaresPipe } from 'src/app/pipes/squares.pipe';
import { FlagsPipe } from 'src/app/pipes/flags.pipe';
import { RecaptchaService } from 'src/app/services/recatpcha/recaptcha.service';
import { GetDIDsService } from '../../services/getDIDs/get-dids.service';
import { IDid } from 'src/app/services/models/dids.model';
import { AuthService } from '@app/services/auth/auth.service';
import { CreditCardComponent } from '@app/core/shared/profile/credit-card/credit-card.component';
import { LoadingService } from '../../services/loading/loading.service';
import { PopUpService } from '@app/services/pop-up/pop-up.service';
import { GoogleTagManagerService } from '@app/services/google-tag-manager/google-tag-manager.service';
import { BtnBackComponent } from '@app/core/shared/buttons/btn-back/btn-back.component';
import { SpinnerSmallComponent } from '@app/core/shared/loading/spinner-small/spinner-small.component';
import { IPackagePrice } from '@app/services/models/package-price.model';

@Component({
  selector: 'app-payment-page',
  standalone: true,
  imports: [
    BillingFormComponent,
    BtnBackComponent,
    CouponCodeComponent,
    CreditCardComponent,
    FlagsPipe,
    PaymentFormComponent,
    RouterModule,
    SquaresPipe,
    SpinnerSmallComponent,
    TranslocoModule,
  ],
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent {
  authService = inject(AuthService);
  couponCodeService = inject(CouponCodeService);
  getDIDsService = inject(GetDIDsService);
  gtmService = inject(GoogleTagManagerService);
  loadingService = inject(LoadingService);
  popUpService = inject(PopUpService);
  packagePriceService = inject(PackagePriceService);
  recaptchaService = inject(RecaptchaService);
  submitOrderService = inject(SubmitOrderService);
  translateService = inject(TranslocoService);

  @ViewChild('paymentForm') paymentForm: PaymentFormComponent;
  @ViewChild('billingForm') billingForm: BillingFormComponent;

  paymentId: number = 0;

  couponCode: string = '';
  currentLang: string;

  couponPrice: number = 0;
  totalPrice: Signal<number> = signal(0);

  packageCountryName: string = this.route.snapshot.params['packageCountry'];
  packageID: number = this.route.snapshot.params['packageID'];
  didID: number = this.route.snapshot.params['didID'];

  packagePrice: number;
  didData: IDid = null;
  orderData = this.submitOrderService.getOrderData();

  paymentCardForm = this.submitOrderService.submitForm.get(
    'paymentForm'
  ) as FormGroup;
  billingAddressForm = this.submitOrderService.submitForm.get(
    'billingForm'
  ) as FormGroup;
  couponForm = this.submitOrderService.submitForm.get(
    'couponForm'
  ) as FormGroup;
  countriesForm = this.submitOrderService.submitForm.get(
    'countriesForm'
  ) as FormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    
  ) {}

  ngOnInit(): void {
    this.loadingService.setLoading(true);

    this.getSelectedDID();

    this.getCurrentLang()
  }

  getCurrentLang() {
    // Subscribe to language changes
    this.translateService.langChanges$.subscribe((lang: string) => {
      this.currentLang = lang; // Update currentLang when language changes
    });

    // Initialize currentLang with the current active language
    this.currentLang = this.translateService.getActiveLang();
  }

  ngAfterViewInit(): void {}

  onSelectedSavedCard(cardId: number) {
    this.paymentId = cardId;
  }
  
  onCouponCodeSubmit(event: string) {
    this.couponCode = event;
    if (this.couponCode !== '') {
      this.validateCouponCode();
    }
  }

  async submitOrder() {
    this.paymentForm.onSubmitPayment();
    this.billingForm.onSubmitBilling();
    const token = await this.recaptchaService.executeRecaptcha(
      'vNumberPurchase'
    );

    if (this.paymentCardForm.valid && this.billingAddressForm.valid) {
      const jsonData = {
        PlatformID: 17,
        CreditCardNumber: this.paymentCardForm.get('cardNumber').value,
        CardHolder: this.paymentCardForm.get('cardHolder').value,
        SecurityCode: this.paymentCardForm.get('cardCVV').value,
        ExpirationMonth: this.paymentCardForm.get('cardExpirationMM').value,
        ExpirationYear: this.paymentCardForm.get('cardExpirationYY').value,
        BillCountry: this.billingAddressForm.get('country').value,
        State: this.billingAddressForm.get('state').value,
        City: this.billingAddressForm.get('city').value,
        Zip: this.billingAddressForm.get('zipCode').value,
        Address: this.billingAddressForm.get('address').value,
        Phone: this.orderData.dialCode + this.orderData.phone,
        Email: this.orderData.email,
        PackageID: this.packageID,
        Password: this.orderData.password,
        FirstName: this.orderData.firstName,
        LastName: this.orderData.lastName,
        LanguageISO: this.currentLang,
        IsSaveCard: 1,
        IsPrimary: 1,
        PromoCode: this.couponForm.get('couponCode').value,
        PromoCodeParams: {
          PackageID: this.packageID,
          DIDID: this.didData.didid.toString(),
        },
        DIDID: this.didData.didid.toString(),
        inquiryTicket: this.orderData.inquiryTicket,
        recaptcha: token,
      };

      this.loadingService.setLoading(true);

      try {
        const res = await this.submitOrderService.submitOrder(jsonData);

        if (res.response_code === 200) {
          this.loadingService.setLoading(false);
          this.gtmService.purchase(this.didData, res.confirmationId, this.packageID, this.packageCountryName, this.totalPrice(), this.couponCode, this.couponPrice)
          this.router.navigate(['/order']);
        } else if (res.response_code === 999) {
          this.loadingService.setLoading(false);
          this.popUpService.openPopUp('server-error');
        } else {
          this.loadingService.setLoading(false);
          this.popUpService.openPopUp(res.response_message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
  
  async validateCouponCode() {
    const jsonData = {
      PackageID: this.packageID,
      DIDID: this.didData?.didid,
      PromoCode: this.couponForm.get('couponCode').value,
      PromoCodeParams: {
        PackageID: this.packageID,
        DIDID: this.didData?.didid,
      },
    };

    try {
      const res = await this.couponCodeService.validateCouponCode(jsonData);

      if (res.response_code === 200) {
        if (res.discount === 0) {
          this.popUpService.openPopUp('code-not-valid');
        } else {
          this.totalPrice = computed(() => {
            return ( this.packagePrice - res.discount );
          });
        }

        this.couponPrice = res.discount;
      }
    } catch (error) {
      console.error('Coupon validation Error: ', error);
    }
  }

  async getPackagePrice() {
    try {
      const jsonData = {
        packageId: Number(this.packageID),
        countryId: this.countriesForm.get('virtualNumberCountryId').value,
        price: 0,
      };
      const res = await this.packagePriceService.getPackagePrice<IPackagePrice>(jsonData);

      if (res.isSuccessful && res.result) {
        this.packagePrice = res.result.price;

        setTimeout(() => {
          this.totalPrice = computed(() => {
            return this.packagePrice;
          });
        }, 1000);

        this.gtmService.addPaymentInfo(this.didData, this.packagePrice, this.packageID, this.packageCountryName, this.couponCode, this.couponPrice)

      }
    } catch (error) {
      console.error(error);
    }
  }

  async getSelectedDID() {
    // const didID = this.route.snapshot.params['didID'];
    const res = await this.getDIDsService.getSelectedDID<IDid>(this.didID);

    if (res.isSuccessful) {
      this.didData = res.result;
      this.getPackagePrice();
      this.loadingService.setLoading(false);
    }
  }

  onRemoveCouponCode() {
    this.couponCode = '';
    this.couponPrice = 0;

    this.totalPrice = computed(() => {
      return this.packagePrice;
    });
  }
}
