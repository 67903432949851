import {
  Component,
  ElementRef,
  Inject,
  inject,
  Input,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import intlTelInput from 'intl-tel-input';
import { InquirysService } from '@app/services/inquirys/inquirys.service';
import { PopUpService } from '@app/services/pop-up/pop-up.service';

@Component({
  selector: 'app-need-more-info-form',
  standalone: true,
  imports: [ReactiveFormsModule, TranslocoModule],
  templateUrl: './need-more-info-form.component.html',
  styleUrl: './need-more-info-form.component.scss',
})
export class NeedMoreInfoFormComponent {
  inquiryService = inject(InquirysService);
  translateService = inject(TranslocoService);
  popUpService = inject(PopUpService);

  @ViewChild('phoneInput') phoneInput!: ElementRef;

  @Input() packageId: string;
  @Input() packageIso: string;

  formSubmitted: boolean = false;

  currentLang: string;
  countryData: string;

  contactForm = this.formBluider.group({
    name: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.pattern(/^[a-zA-Z\s]*$/),
        Validators.pattern(/^[\p{L}\s]*$/u),
        Validators.maxLength(30),
      ]),
    ],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    phone: ['', Validators.required],
    dialCode: [''],
  });

  constructor(
    private formBluider: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    // Subscribe to language changes
    this.translateService.langChanges$.subscribe((lang: string) => {
      this.currentLang = lang; // Update currentLang when language changes
    });

    // Initialize currentLang with the current active language
    this.currentLang = this.translateService.getActiveLang();
  }

  async onSubmitContactForm() {
    this.formSubmitted = true;

    if (this.contactForm.valid) {

      const { email, name, dialCode, phone } = this.contactForm.value;

      const jsonData = {
        email: email,
        name: name,
        phone: dialCode + phone,
        languageISO: this.currentLang,
        packageId: this.packageId,
      };

      try {
        const res = await this.inquiryService.contactUsInquiry(jsonData);

        if (res.isSuccessful && res.result) {
          this.contactForm.reset();
          this.formSubmitted = false;
          this.popUpService.openPopUp('contact');
        } else {
          this.popUpService.openPopUp('server-error');
        }
      } catch (error) {
        console.error(error);
        this.popUpService.openPopUp('server-error');
      }
    }
  }

  ngAfterViewInit() {
    if (this.platformId === 'browser') {
      const inputElement = this.phoneInput.nativeElement;
      if (inputElement) {
        const iti = intlTelInput(inputElement, {
          initialCountry: this.packageIso,
          separateDialCode: true,
          formatOnDisplay: true,
          strictMode: true,
          loadUtilsOnInit:
            'https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/js/utils.js',
        });

        inputElement.addEventListener('input', () => {
          const phoneNumber = iti.getNumber();
          const dialCode = iti.getSelectedCountryData().dialCode;
          const nationalNumber = phoneNumber.replace(`+${dialCode}`, '').trim();
          this.countryData = iti.getSelectedCountryData().name;

          this.contactForm.get('phone').setValue(nationalNumber);
          this.contactForm.get('dialCode').setValue(dialCode);
          this.contactForm.get('phone').updateValueAndValidity();
        });
      }
    }
  }

  phoneNumberValidator(
    control: FormControl
  ): Promise<{ [key: string]: boolean } | null> {
    return new Promise((resolve) => {
      const cleanedNumber = control.value;

      if (cleanedNumber) {
        const input = document.querySelector('#phoneInput') as HTMLInputElement;
        const iti = intlTelInput.getInstance(input);

        if (iti.isValidNumber()) {
          resolve(null);
        } else {
          resolve({ invalidPhoneNumber: true });
        }
      } else {
        resolve(null);
      }
    });
  }
}
